import {createBrowserRouter} from 'react-router-dom';

import Login from '../components/Login';
import Register from '../components/Register';
import Dashboard from '../components/Dashboard';
import Sidebar from '../components/Sidebar';
import Test from '../components/Test';
import AddService from '../components/services/AddService';
import ListService from '../components/services/ListService';
import Confirm from '../components/Confrim';
import CompteAttente from '../components/comptes/CompteAttente';
import ComptePro from '../components/comptes/ComptePro';
import CompteDepanneur from '../components/comptes/CompteDepanneur';
import CompteClient from '../components/comptes/CompteClient';
import AddAnnonce from '../components/annonces/AddAnnonce';
import AnnonceAttente from '../components/annonces/AnnonceAttente';
import AllAnnonce from '../components/annonces/AllAnnonce';
import AnnonceArchive from '../components/annonces/AnnonceArchive';
import AnnonceBy from '../components/annonces/AnnonceBy';
import Professionnel from '../components/helps/Professionnel';
import ProfessionnelSingle from '../components/helps/ProfessionnelSingle';
import ProfessionnelProfile from '../components/helps/ProfessionnelProfile';
import ProfessionnelDemandeMe from '../components/helps/ProfessionnelDemandeMe';
import CarteInfo from '../components/helps/CarteInfo';
import ProfessionnelDemandeMeYes from '../components/helps/ProfessionnelDemandeMeYes';
import ProfessionnelDemandeClient from '../components/helps/ProfessionnelDemandeClient';
import OpenStreet from '../components/maps/OpenStreet';
import Map from '../components/maps/Map';

import AllDemandeAdmin from '../components/helps/AllDemandeAdmin';
import DemandeDepanneur from '../components/depannage/DemandeDepanneur';
import PositionTwo from '../components/maps/PositionTwo';
import WaitClientDepanneur from '../components/depannage/WaitClientDepanneur';
import ProfileDepanneur from '../components/depannage/ProfileDepanneur';
import WaitDepanneur from '../components/depannage/WaitDepanneur';
import AllRequestClient from '../components/depannage/AllRequestClient';
import AllRequestDepanneur from '../components/depannage/AllRequestDepanneur';
import AllRequestAdmin from '../components/depannage/AllRequestAdmin';
import ListNotification from '../components/notifications/ListNotification';
import AllReviews from '../components/notifications/AllReviews';
import ProfileCompte from '../components/comptes/ProfileCompte';
import ComptePassword from '../components/comptes/CompePassword';
import OtpReset from '../components/OtpReset';
import Forgot from '../components/Forgot';
import RessetPassword from '../components/ResetPassword';
import Home from '../components/Home';

export const routes = createBrowserRouter([
    {
        path:'/',
        element:<Home/>
    },
{
    path:'/login',
    element:<Login/>
},
{
    path:'/register',
    element:<Register/>
},
{
    path:'/forgot_password',
    element:<Forgot/>
},
{
    path:'/otp_reset',
    element:<OtpReset/>
},
{
    path:'/reset_password',
    element:<RessetPassword/>
},
{
    path:'/confirm_account',
    element:<Confirm/>
},
{
    path:'/Sidebar',
    element:<Sidebar/>
},
{
    path:'/Test',
    element:<Test/>
},
{
    path:'/dashboard',
    element:<Dashboard/>
},
,
{
    path:'/services/add',
    element:<AddService/>
},
{
    path:'/services/list',
    element:<ListService/>
},
{
    path:'/comptes/en_attente',
    element:<CompteAttente/>
},
{
    path:'/comptes/professionnel',
    element:<ComptePro/>
},
{
    path:'/comptes/depanneur',
    element:<CompteDepanneur/>
},
{
    path:'/comptes/client',
    element:<CompteClient/>
},

{
    path:'/annonces/add',
    element:<AddAnnonce/>
},
{
    path:'/annonces/attente',
    element:<AnnonceAttente/>
},
{
    path:'/annonces/all',
    element:<AllAnnonce/>
},

{
    path:'/annonces/archive',
    element:<AnnonceArchive/>
},

{
    path:'/annonces/steps',
    element:<AnnonceBy/>
},

{
    path:'/helps/professionnel',
    element:<Professionnel/>
},
{
    path:'/professionnel/single/:id',
    element:<ProfessionnelSingle/>
},
{
    path:'/professionnel/profile/:id',
    element:<ProfessionnelProfile/>
},
{
    path:'/helps/professionnel/demande/attente',
    element:<ProfessionnelDemandeMe/>
},
{
    path:'/helps/professionnel/demande/traiter',
    element:<ProfessionnelDemandeMeYes/>
},
{
    path:'/helps/professionnel/demande/client',
    element:<ProfessionnelDemandeClient/>
},
{
    path:'/helps/professionnel/all',
    element:<AllDemandeAdmin/>
},
{
    path:'/helps/professionnel/demande/carte/:id',
    element:<CarteInfo/>
},
{
    path:'/helps/depanneur/demande',
    element:<DemandeDepanneur/>
},
{
    path:'/helps/depanneur/waitclient',
    element:<WaitClientDepanneur/>
},
{
    path:'/helps/depanneur/client/allrequest',
    element:<AllRequestClient/>
},
{
    path:'/helps/depanneur/depanneur/allrequest',
    element:<AllRequestDepanneur/>
},
{
    path:'/helps/depanneur/admin/allrequest',
    element:<AllRequestAdmin/>
},
{
    path:'/helps/depanneur/wait',
    element:<WaitDepanneur/>
},
{
    path:'/helps/depanneur/profile/:id',
    element:<ProfileDepanneur/>
},
{
    path:'/auth/notification',
    element:<ListNotification/>
},
{
    path:'/auth/admin/reviews',
    element:<AllReviews/>
},
{
    path:'/auth/profile/:id',
    element:<ProfileCompte/>
},

{
    path:'/auth/profile/password',
    element:<ComptePassword/>
},

{
    path:'/auth/openstreet',
    element:<OpenStreet/>
},

{
    path:'/auth/map',
    element:<Map/>
},
{
    path:'/helps/depannage/chemin/:lat1/:long1/:lat2/:long2',
    element:<PositionTwo/>
},

]);

export default routes;
