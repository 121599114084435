import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import { GoogleMap, LoadScript, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';
import Sidebar from '../Sidebar';

const PositionTwo = () => {

    const {lat1}=useParams();
    const {lat2}=useParams();

    const {long1}=useParams();
    const {long2}=useParams();
    
  
    const [response, setResponse] = useState(null);
    const [origin, setOrigin] = useState({ lat: parseFloat(lat2), lng: parseFloat(long2) }); // Position par défaut : New York
    const [destination, setDestination] = useState({ lat: parseFloat(lat1), lng: parseFloat(long1) }); // Position par défaut : Los Angeles
    const [directionsService, setDirectionsService] = useState(null);
    const [fetchDirections, setFetchDirections] = useState(true);

    useEffect(() => {
        const waitForGoogleMaps = () => {
            if (window.google && window.google.maps) {
                setDirectionsService(new window.google.maps.DirectionsService());
            } else {
                setTimeout(waitForGoogleMaps, 100); // Réessayer après un court délai
            }
        };

        waitForGoogleMaps(); // Commencer à attendre le chargement de l'API Google Maps
    }, []);

    useEffect(() => {
        if (!directionsService || !fetchDirections) return;

        directionsService.route(
            {
                origin: origin,
                destination: destination,
                travelMode: 'DRIVING'
            },
            (result, status) => {
                if (status === 'OK') {
                    setResponse(result);
                    console.log("Directions retrieved:", result);
                } else {
                    console.error('Erreur lors de la récupération des directions :', status);
                }
            }
        );

        informations();

    }, [directionsService, origin, destination, fetchDirections]);

const informations=()=>{

}

    return (
        <Sidebar>
        <LoadScript
            googleMapsApiKey="AIzaSyAOoPr9ZlqUdNHWfYpzSbioRNJqLq39dZs"
            onError={(error) => console.error('Erreur lors du chargement de l\'API Google Maps :', error)}
        >
            <GoogleMap
                mapContainerStyle={{
                    width: '100%',
                    height: '400px'
                }}
                zoom={11}
                center={origin}
            >
                {response && (
                    <>
                        <DirectionsRenderer
                            options={{
                                directions: response
                            }}
                        />
                        {response.routes && response.routes.length > 0 && (
                            <div style={{ position: 'absolute', top: 10, left: 10, backgroundColor: 'white', padding: 10, zIndex: 1000 }}>
                                <div>
                                    Start Location: {response.routes[0].legs[0].start_address}<br />
                                    End Location: {response.routes[0].legs[0].end_address}<br />
                                    Distance: {response.routes[0].legs[0].distance.text}<br />
                                    Duration: {response.routes[0].legs[0].duration.text}
                                </div>
                            </div>
                        )}
                    </>
                )}
            </GoogleMap>
           
        </LoadScript>

        </Sidebar>
    );
};

export default PositionTwo;
