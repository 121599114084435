import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Sidebar  from '../Sidebar';

import { BASE_URL, getToken, getUser } from '../../config';
import { toast } from 'react-toastify';


const ComptePassword = () => {
 
  let user_id=getUser();
  let token=getToken();

  let [current,setCurrent] = useState("");
  let [news,setNews] = useState("");

  const [showPassword, setShowPassword] = useState(false);


  const [watingServer, setWatingServer] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const add= async(e)=>{
    e.preventDefault();
    
      setWatingServer(true);

      const info= {current,news};

      const headers = {
        "Content-type" : "application/json",
        'Authorization': `Bearer ${token}`
      };
      
      await axios.post(`${BASE_URL}auth/updatePassword`,info ,{headers}).then(({data})=>{
    
        if(data.etat == true){
          setWatingServer(false);
          toast.success(data.message);
          setCurrent("");
          setNews("");
          console.log(data);
          
        }else{
          setWatingServer(false);
          console.log(data);
         toast.error(data.message);
        }

      }).catch(({response})=>{
        if(response.status == 422){
          console.log("Erros");
        }
      });

    
  }

  return (
    <div>
    <Sidebar>
    <h1 className="h3 mb-0 text-gray-800 text-start">Ajouter un nouveau service</h1>
     <hr className="bg-dark" />
    <div className="row mt-3">
    
    <form onSubmit={add}>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Mot de passe actuelle</label>
    <input type="password" className="form-control" id="psw" required minLength="8" value={current} onChange={e=>setCurrent(e.target.value)} />
  </div>

  <div className="mb-3">
        <label htmlFor="newPassword" className="form-label">Nouveau Mot de passe</label>
        <div className="input-group">
          <input type={showPassword ? 'text' : 'password'} className="form-control" id="newPassword" required minLength="8" value={news} onChange={e => setNews(e.target.value)} />
          <button type="button" className="btn btn-outline-secondary" onClick={togglePasswordVisibility}>
            <i className={`fas fa-eye${showPassword ? '-slash' : ''}`}></i>
          </button>
        </div>
      </div>

  {!watingServer && <button type="submit" class="btn btn-primary"><i class="fas fa-pen-nib"></i> Modifier</button>}
  {watingServer && <button type="submit" class="btn btn-success" disabled><i class="fas fa-spinner"></i> Patientez s'il vous plait ...</button>}
</form>
    </div>

    </Sidebar>
    </div>
  );
};

export default ComptePassword;
