import React, { useEffect, useRef, useState  } from 'react';
import Sidebar from '../Sidebar';
import { BASE_URL, getUser, getToken, price_km,pource} from '../../config';
import { Link,useNavigate } from "react-router-dom";

import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import axios from 'axios';



const DemandeDepanneur = () => {

    const navigate=useNavigate();

  const departureInputRef = useRef(null);
  const destinationInputRef = useRef(null);
  const [departurePlace, setDeparturePlace] = useState('');
  const [destinationPlace, setDestinationPlace] = useState('');
  const [prix, setPrix] = useState('');
  const [isDisplay, setIsDisplay] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [positionD, setPositionD] = useState(null);
  const [positionA, setPositionA] = useState(null);
  const [loadded,setLoadded]=useState(false);
  const [data,setData]=useState();
  const [posit,setPosit]=useState(null);

  

  const [read,setRead]=useState(false);

  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);


   const token=getToken();
   const user_id=getUser();

  useEffect(() => {
 
    const googlePlacesScript = document.createElement('script');
    googlePlacesScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAOoPr9ZlqUdNHWfYpzSbioRNJqLq39dZs&libraries=places,geometry`;
    googlePlacesScript.async = true;
    googlePlacesScript.onload = () => {

        setGoogleMapsLoaded(true);


      const departureAutocomplete = new window.google.maps.places.Autocomplete(departureInputRef.current);
      const destinationAutocomplete = new window.google.maps.places.Autocomplete(destinationInputRef.current);

      departureAutocomplete.addListener('place_changed', () => {
        const place = departureAutocomplete.getPlace();
        if (place.geometry && place.geometry.location) {
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          setPositionD({ latitude: lat, longitude: lng });
          setDeparturePlace(place.formatted_address);
        } else {
          console.error('Place does not have a valid geometry');
        }
      });

      destinationAutocomplete.addListener('place_changed', () => {
        const place = destinationAutocomplete.getPlace();
        if (place.geometry && place.geometry.location) {
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          setPositionA({ latitude: lat, longitude: lng });
          setDestinationPlace(place.formatted_address);
        } else {
          console.error('Place does not have a valid geometry');
        }
      });
    };

    document.body.appendChild(googlePlacesScript);

    return () => {
      const googlePlacesScript = document.querySelector("script[src^='https://maps.googleapis.com/maps/api/js']");
      if (googlePlacesScript && googlePlacesScript.parentNode) {
        googlePlacesScript.parentNode.removeChild(googlePlacesScript);
      }
    };

    
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsWaiting(true);
    console.log('Lieu de départ :', departurePlace);
    console.log('Lieu de destination :', destinationPlace);

    if (positionD && positionA && googleMapsLoaded) {
        calculateDistance(departurePlace, destinationPlace)
        .then(distance => {
            const wdist = distance.replace(/,/g, '');
            console.log(`La distance entre ${departurePlace} et ${destinationPlace} est de : ${wdist}`);
            let calc = parseFloat(wdist) * price_km;
            setPrix(calc.toFixed(2));
        })
        .catch(error => {
            console.error('Erreur lors du calcul de la distance :', error);
        });
      }

    setTimeout(() => {
      if (departurePlace != null) {
        setIsWaiting(false);
      }

      setIsDisplay(true);
    }, 500);
  };

   

  const calculateDistance = (origin, destination) => {
    return new Promise((resolve, reject) => {
        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
            {
                origin: origin,
                destination: destination,
                travelMode: 'DRIVING'
            },
            (result, status) => {
                if (status == 'OK') {
                    const distance = result.routes[0].legs[0].distance.text;
                    resolve(distance);
                } else {
                    reject(status);
                }
            }
        );
    });
};


  const handleUseCurrentPosition = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const geocoder = new window.google.maps.Geocoder();
          const latlng = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          geocoder.geocode({ 'location': latlng }, (results, status) => {
            if (status == 'OK') {
              if (results[0]) {
                const formattedAddress = results[0].formatted_address;
                const lat = results[0].geometry.location.lat();
                const lng = results[0].geometry.location.lng();
                setPositionD({ latitude: lat, longitude: lng });
                setDeparturePlace(formattedAddress);
              } else {
                console.error('Aucun résultat trouvé');
              }
            } else {
              console.error('Geocoder a échoué en raison de :', status);
            }
          });
        },
        (error) => {
          console.error('Erreur lors de la récupération de la position :', error.message);
        }
      );
    } else {
      console.error("La géolocalisation n'est pas prise en charge par ce navigateur.");
    }

    // Supprimer le script s'il est présent dans le DOM
    const googlePlacesScript = document.querySelector("script[src^='https://maps.googleapis.com/maps/api/js']");
    if (googlePlacesScript && googlePlacesScript.parentNode) {
      googlePlacesScript.parentNode.removeChild(googlePlacesScript);
    }
  };

  
  const getCurrentPosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  };

  const trouverDepanneurLePlusProche = (positions, positionActuelle, depanneurs) => {
    let plusProche = null;
    let distanceMinimale = Infinity;
    let depanneurPlusProche = null;
    const maxDistance = 20 * 1000; // 20 km en mètres

    if (window.google && window.google.maps && window.google.maps.geometry && window.google.maps.geometry.spherical) {
      positions.forEach((position, index) => {
        const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
          new window.google.maps.LatLng(position.latitude, position.longitude),
          new window.google.maps.LatLng(positionActuelle.latitude, positionActuelle.longitude)
        );
        if (distance < distanceMinimale && distance < maxDistance) {
          plusProche = position;
          distanceMinimale = distance;
          depanneurPlusProche = depanneurs[index];
        }
      });
    } else {
      console.error('La bibliothèque Google Maps ou la fonction spherical n\'est pas chargée');
    }
  
    return { plusProche, depanneurPlusProche };
};


const commander = async (e) => {
    e.preventDefault();

    setLoadded(true);

    try {
      const response = await axios.get(`${BASE_URL}depanneur_active`, {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      });
  
      // Récupérez les positions de tous les dépanneurs actifs
      const positions = await Promise.all(response.data.map(async (depanneur) => {
        // Récupérez la position actuelle du dépanneur
        const position = await getCurrentPosition();
        return {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
      }));
  
      // Comparez les positions pour trouver le plus proche
      const positionActuelle = { latitude:positionD.latitude, longitude: positionD.longitude};
      const { plusProche, depanneurPlusProche } = trouverDepanneurLePlusProche(positions, positionActuelle, response.data);
    
      console.log("Dépanneur le plus proche :", depanneurPlusProche);
      console.log("Position du dépanneur le plus proche :", plusProche);
      setPosit(plusProche);
      setData(depanneurPlusProche);
      setRead(true);
      setLoadded(false);
      console.log("Information")
      console.log(depanneurPlusProche);
      console.log(plusProche);

    } catch (error) {
      console.error('Erreur lors de la récupération des informations:', error);
    }
    setLoadded(false);
};
 
const Send=async(e)=>{
    e.preventDefault();
    
      const headers = {
        'Authorization': `Bearer ${token}`
      };
             
      const info={user_dep:data.user.id,user_client:user_id,lat_arr:positionA.latitude,long_arr:positionA.longitude,lat_depart:positionD.latitude,long_depart:positionD.longitude,prix_totale:prix,pourcentage:pource}

      await axios.post(`${BASE_URL}depanneur/send`, info ,{headers}).then(({data})=>{
    
        if(data.etat == true){
         toast.success(data.message);
         navigate('/helps/depanneur/waitclient');

        }else{
          toast.success(data.message);
        }
        
      }).catch(({response})=>{
        if(response.status == 422){
          console.log("Erros");
        }
      });

}

  return (
    <div>
      <Sidebar>
        <div className='row'>
          <div className='col-sm-12 col-lg-6 col-md-6'>
            <form onSubmit={handleSubmit}>
              <label htmlFor='departure' className='fw-bold'>
                Départ :
              </label>
              <div className='input-group'>
                <input
                  id='departure'
                  ref={departureInputRef}
                  type='text'
                  required
                  className='form-control'
                  value={departurePlace}
                  onChange={(e) => setDeparturePlace(e.target.value)}
                />
                <button type='button' className='btn btn-outline-secondary' onClick={handleUseCurrentPosition}>
                  Ma position
                </button>
              </div>
              <div>
                <label htmlFor='destination' className='fw-bold'>
                  Destination :
                </label>
                <input
                  id='destination'
                  ref={destinationInputRef}
                  type='text'
                  required
                  className='form-control'
                  placeholder='Saisir lieu de destination'
                />
              </div>
              {!isWaiting && <button type='submit' className='btn btn-dark mt-3'>
                Rechercher
              </button>}
              {isWaiting && <button type='button' class='btn btn-success mt-3' disabled><i class='fas fa-spinner'></i> Patientez s'il vous plait ...</button>}
            </form>
          </div>
          {isDisplay && 
            <>
          <div className='col-sm-12 col-lg-6 col-md-6 mt-3'>
            <div class='card'>
              <div className='card-body'>
                <h5 className='card-title'>Information de la demande</h5>
                <hr className='bg-dark' />
                <p className='card-text'>Départ : {departurePlace}</p>
                <p className='card-text'>Déstination : {destinationPlace} </p>
                <p className='card-text'>Prix : {prix} DZD</p>
             {!loadded && <button class='btn btn-primary' onClick={commander}>Commander</button> }
             {loadded && <button class='btn btn-info' disabled>Wait ....</button> }

                <Link className="btn btn-info ms-4" target='_blank' to={`/helps/depannage/chemin/${positionA.latitude}/${positionA.longitude}/${positionD.latitude}/${positionD.longitude}`}>Details</Link>

              </div>
            </div>
          </div>
          </>
          }
          {loadded && 
            <div className="d-flex justify-content-center mt-3">
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
          </div>
          }

           {read && data && (
              <div class="card mt-3">
  <div class="card-header">
    Dépannage
  </div>
  <div class="card-body">
    <h5 class="card-title">Nom : <span className='text-primary'>{data.user.name} {data.user.last_name}</span></h5>
    <h5 class="card-title">Telephone : <a className='text-success' href={"tel:+"+data.user.phone}>{data.user.phone}</a></h5>
    <h5 class="card-title">Email : <span className='text-success'>{data.user.email}</span></h5>
    <p class="card-text fw-bold">Voiture : {data.vehicule}</p>
    <button class="btn btn-primary" onClick={Send}>Envoyer la demande</button>
    <Link target='_blank' to={"/helps/depanneur/profile/"+data.user_id} className='btn btn-success ms-4'>Voir le profile</Link>
  </div>
</div>  
)}  
 {read && data == null && (
        <p className='text-center mt-3'>Aucun dépanneur trouvé !</p>
      )}
        </div>
      </Sidebar>
    </div>
  );
};

export default DemandeDepanneur;
