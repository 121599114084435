import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Sidebar  from '../Sidebar';
import $ from 'jquery';
import 'datatables.net';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';


import { BASE_URL,getUser,getToken } from '../../config';


const AllDemandeAdmin = () => {

  let user_id=getUser();
  let token=getToken();
        
   let [data,setData]=useState(null);
   const [isWaiting, setIsWaiting] = useState(true);

   const [isDataTableInitialized, setDataTableInitialized] = useState(false);
 

  const fetchAccount = async () => {
    try {
      const response = await axios.get(`${BASE_URL}demandes/admin`, {
          headers: {
            "Content-type" : "application/json",
            "Authorization" : `Bearer ${token}`,
          },
        });
        console.log(user_id);
        setIsWaiting(false);
         setData(response.data);
         console.log(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des informations:', error);
    }
  }

  useEffect(()=>{
   
    fetchAccount();
  },[]);


 
  const decision_demande = async (id, decision) => {
    let rep="";
    let etat="";
    if(decision == -2){
      rep="Êtes-vous sûr de vouloir annuler cette demande";
      etat="question";
    }else{
        rep="Êtes-vous sûr de vouloir supprimer cette demande";
        etat="error"; 
    }
    Swal.fire({
      title: rep,
      text: "Cette action est irréversible!",
      icon: etat,
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler'
  }).then(async (result) => {
      if (result.isConfirmed) {
    try {
        const response = await axios.post(`${BASE_URL}reponse_clients/${id}/${decision}`, null, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`,
            }
        });
        
        if (response.data.etat == true) {
            console.log("Success");
            toast.success(response.data.message);
        } else {
            console.log('error');
            toast.error(response.data.message);
        }
            fetchAccount();
          if (data.length == 0) {
            $('#dataTable').DataTable().clear().destroy(); 
              setDataTableInitialized(false);
          }
    } catch (error) {
        if (error.response && error.response.status == 422) {
            console.log(error.response.data.errors);
        } else {
            console.log(error);
        }
    }
  }
});
}


useEffect(() => {
  
  if (data && data.length > 0 && !isDataTableInitialized) {
      $('#dataTable').DataTable({
          language: {
              paginate: {
                  previous: '<i class="fas fa-angle-double-left"></i>',
                  next: '<i class="fas fa-angle-double-right"></i>'
              }
          },
          // Add any other DataTable configurations here
      });
      setDataTableInitialized(true);
  } else if ((!data || data.length == 0) && isDataTableInitialized) {
      $('#dataTable').DataTable().clear().destroy(); // Clear and destroy the DataTable
      $('#dataTable').parent().html('<p class="text-center">Aucune information disponible</p>'); // Display message
      setDataTableInitialized(false);
  }
}, [data, isDataTableInitialized]);


  return (
    <div>
    <Sidebar>
    <h1 className="h3 mb-0 text-gray-800 text-start">Listes des demandes Professionnel</h1>
     <hr className="bg-dark" />
     {isWaiting ? (
       <div className="d-flex justify-content-center">
       <div className="spinner-border" role="status">
           <span className="visually-hidden">Loading...</span>
       </div>
   </div>
    ) : (
     <div class="card-body">
                            <div class="table-responsive">
                            {data && data.length > 0 ? (
                                <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                        <th className="no-sort">ID</th>
                                            <th>Entreprise</th>
                                            <th>Client</th>
                                            <th>Service</th>
                                            <th>Date</th>
                                            <th>Postion Client</th>
                                            <th></th>
                                         
                                        </tr>
                                    </thead>

                                    <tbody>
                                    {data && data.map((post) => (
                                            <tr key={post.id}>
                                                <td>{post.id}</td>
                                            <td><Link target='_blank' to={"/professionnel/profile/"+post.user_pro.id}>{post.user_pro.professionnel.nom_entreprise}</Link></td>
                                            <td>{post.user_client.name} {post.user_client.last_name} | {post.user_client.email} | {post.user_client.phone}</td>
                                            <td className='badge bg-info text-center'>{post.service.name_service}</td>
                                            <td>{post.created_at}</td>
                                            <td>
                                            
                                                <Link className='btn btn-primary' to={"/helps/professionnel/demande/carte/"+post.id}>
                                                <i class="fas fa-map-marker-alt"></i>
                                                </Link>
                                            </td>
                                            <td>
                                          {post.accept == -3 && <span class="badge bg-danger">Supprimer par client</span>}
                                            {post.accept == -1 && <span class="badge bg-danger">Refusé par entreprise</span>}
                                            {post.accept == 0 && <span class="badge bg-primary">En attente</span>}
                                             {post.accept == 1 && <span class="badge bg-success">Accepté</span>}
                                             {post.accept == 2 && <span class="badge bg-danger">annuler par Professionnel</span>}
                                             {post.accept == -2 && <span class="badge bg-warning">annuler par client</span>}        
                                            </td>
                                          

                                          
                                        </tr>
                                       ))}
                                        </tbody>
                            </table>
                             ) : (
                              <p className="text-center">Aucune information disponible</p>
                            )}
                            </div>
                            </div>
                            )}
    </Sidebar>
    </div>
  );
};

export default AllDemandeAdmin;
