import logo from './logo.svg';
import './App.css';
import { RouterProvider} from 'react-router-dom';

import {routes} from './routes/index.js';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className='App'>
    
<RouterProvider router={routes}></RouterProvider>

<ToastContainer />
    </div>     
    );
    }
        export default App;
