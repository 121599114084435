import Cookies from 'js-cookie';
import {jwtDecode} from "jwt-decode";



const BASE_URL = 'https://sostonobile.com/api-prestation/public/api/';
//const BASE_URL = 'http://localhost:8000/api/';

//const user_id = Cookies.get('user_id');
//const role = Cookies.get('role');

const getRole = () => {
    return Cookies.get('role');
};

  const getUser = () => {

    const jwt = Cookies.get('user_id');
    const decoded = jwtDecode(jwt);
    return decoded.user_id;
    
  };

  const getToken = () => {
    return Cookies.get('token');
  };

const price_km = 60;
const pource=15;

const Lien = 'https://sostonobile.com/api-prestation/';

const token = Cookies.get('token');

export { BASE_URL,getUser , getToken  , Lien ,getRole , price_km , pource};