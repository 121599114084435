import React, { useEffect, useState, useRef } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link, useLocation } from 'react-router-dom';

import '../assets/vendor/bootstrap/css/bootstrap.min.css';
import '../assets/vendor/bootstrap-icons/bootstrap-icons.css';
import '../assets/vendor/aos/aos.css';
import '../assets/vendor/glightbox/css/glightbox.min.css';
import '../assets/vendor/swiper/swiper-bundle.min.css';
import '../assets/css/main.css';

const Home = () => {
  const [activeLink, setActiveLink] = useState('');
  const [mobileNavActive, setMobileNavActive] = useState(false);
  const location = useLocation();
  const scrollTopBtnRef = useRef(null);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      offset: 200,
    });

    const handleScroll = () => {
      if (window.scrollY > 100) {
        scrollTopBtnRef.current?.classList.add('active');
      } else {
        scrollTopBtnRef.current?.classList.remove('active');
      }
    };

    const handleScrollTopBtnClick = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };

    window.addEventListener('scroll', handleScroll);
    scrollTopBtnRef.current?.addEventListener('click', handleScrollTopBtnClick);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      scrollTopBtnRef.current?.removeEventListener('click', handleScrollTopBtnClick);
    };
  }, []);

  useEffect(() => {
    setActiveLink(location.hash || '#hero');
  }, [location]);

  const handleLinkClick = (hash) => {
    setActiveLink(hash);
    setMobileNavActive(false); // Close mobile nav on link click
  };

  const handleMobileNavToggle = () => {
    setMobileNavActive(!mobileNavActive);
  };

  useEffect(() => {
    if (mobileNavActive) {
      document.body.classList.add('mobile-nav-active');
    } else {
      document.body.classList.remove('mobile-nav-active');
    }
  }, [mobileNavActive]);
  return (
    <div>
      
      <header id="header" className="header d-flex align-items-center fixed-top">
        <div className="container-fluid container-xl position-relative d-flex align-items-center">
          <Link to="/" className="logo d-flex align-items-center me-auto">
            <img src="/assets_public/img/logo.png" alt="" width="160" height="100" />
            <h1 className="sitename">Sos Tonobile</h1>
          </Link>
          <nav id="navmenu" className={`navmenu ${mobileNavActive ? 'mobile-nav-active' : ''}`}>
            <ul>
              <li>
                <a
                  href="#hero"
                  className={activeLink == '#hero' ? 'active' : ''}
                  onClick={() => handleLinkClick('#hero')}
                >
                  Accueil
                </a>
              </li>
              <li>
                <a
                  href="#about"
                  className={activeLink == '#about' ? 'active' : ''}
                  onClick={() => handleLinkClick('#about')}
                >
                  A Propos
                </a>
              </li>
              <li>
                <a
                  href="#services"
                  className={activeLink == '#services' ? 'active' : ''}
                  onClick={() => handleLinkClick('#services')}
                >
                  Services
                </a>
              </li>
              <li>
                <a
                  href="#contact"
                  className={activeLink == '#contact' ? 'active' : ''}
                  onClick={() => handleLinkClick('#contact')}
                >
                  Contact
                </a>
              </li>
              <li>
                <Link to="/" className='d-inline'>
                  <span><img src="assets_public/img/fr.png" alt="" width="20" height="20" /></span>
                </Link>
                <Link to="/" className='d-inline'>
                  <span><img src="assets_public/img/us.png" alt="" width="20" height="20" /></span>
                </Link>
                <Link to="/" className='d-inline'>
                  <span><img src="assets_public/img/dz.png" alt="" width="20" height="15" /></span>
                </Link>
              </li>
            </ul>
            <i
              className={`mobile-nav-toggle d-xl-none bi ${mobileNavActive ? 'bi-x' : 'bi-list'}`}
              onClick={handleMobileNavToggle}
            ></i>
          </nav>
          <Link className="btn-getstarted" to="/register">Compte</Link>
        </div>
      </header>

      <main className="main">
        <section id="hero" className="hero section">
          <div className="hero-bg">
            <img src="assets_public/img/hero-bg-light.webp" alt="" />
          </div>
          <div className="container text-center">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h1 data-aos="fade-up" className="">Bienvenue dans notre Platforme de prestation des<span> Services Automobiles</span></h1>
              <p data-aos="fade-up" data-aos-delay="100" className="">
                Démarrez rapidement votre projet dès maintenant et préparez le terrain pour le succès<br />
              </p>
              <div class="d-flex" data-aos="fade-up" data-aos-delay="200">
                <Link to="/login" class="btn-get-started">Commencer</Link>
                <a href="https://www.youtube.com/" target='_blank' class="glightbox btn-watch-video d-flex align-items-center">
                  <i class="bi bi-play-circle"></i><span>Voir la Video</span>
                </a>
              </div>
              <img src="assets_public/img/logo.png" className="img-fluid hero-img" alt="" data-aos="zoom-out" data-aos-delay="300" />
            </div>
          </div>
        </section>
      </main>

      <section id="about" className="about section">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-6 content" data-aos="fade-up" data-aos-delay="100">
              <p className="who-we-are">A Propos de sos tonobile</p>
              <p className="fst-italic">
                Un Site et une application web pour faciliter la prestation du service pour les gens qui tombent souvent en panne avec un service de 24/7 et sur 48 wilayas avec un simple clic.
              </p>
            </div>
            <div className="col-lg-6 about-images" data-aos="fade-up" data-aos-delay="200">
              <div className="row gy-4">
                <div className="col-lg-6">
                  <img src="/assets_public/img/v1.jpg" className="img-fluid" alt="" />
                </div>
                <div className="col-lg-6">
                  <div className="row gy-4">
                    <div className="col-lg-12">
                      <img src="/assets_public/img/v2.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-12">
                      <img src="/assets_public/img/v3.jpg" className="img-fluid" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="services" className="features section">
        <div className="container section-title" data-aos="fade-up">
          <h2 className="">Services</h2>
        </div>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-5 d-flex align-items-center">
              <ul className="nav nav-tabs" data-aos="fade-up" data-aos-delay="100">
                <li className="nav-item">
                  <a className="nav-link active show" data-bs-toggle="tab" data-bs-target="#features-tab-1">
                    <i className="bi bi-building"></i>
                    <div>
                      <h4 className="d-none d-lg-block">Services Entreprises .</h4>
                      <p>Services Entreprises</p>
                    </div>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" data-bs-target="#features-tab-2">
                    <i className="bi bi-car-front"></i>
                    <div>
                      <h4 className="d-none d-lg-block">Services Dépannage</h4>
                      <p>Services Dépannage</p>
                    </div>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" data-bs-target="#features-tab-3">
                    <i className="bi bi-people"></i>
                    <div>
                      <h4 className="d-none d-lg-block">Services Clients</h4>
                      <p>Services Clients</p>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <div className="tab-content" data-aos="fade-up" data-aos-delay="200">
                <div className="tab-pane fade active show" id="features-tab-1">
                  <img src="/assets_public/img/v1.jpg" alt="" className="img-fluid" />
                </div>
                <div className="tab-pane fade" id="features-tab-2">
                  <img src="/assets_public/img/v2.jpg" alt="" className="img-fluid" />
                </div>
                <div className="tab-pane fade" id="features-tab-3">
                  <img src="/assets_public/img/v3.jpg" alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      
      <section id="contact" class="contact section">

<div class="container section-title" data-aos="fade-up">
  <h2>Contact</h2>
</div>

<div class="container" data-aos="fade-up" data-aos-delay="100">

  <div class="row gy-4">

    <div class="col-lg-6">
      <div class="info-item d-flex flex-column justify-content-center align-items-center" data-aos="fade-up" data-aos-delay="200">
        <i class="bi bi-geo-alt"></i>
        <h3>Address</h3>
        <p>Bouira , Algérie</p>
      </div>
    </div>

    <div class="col-lg-3 col-md-6">
      <div class="info-item d-flex flex-column justify-content-center align-items-center" data-aos="fade-up" data-aos-delay="300">
        <i class="bi bi-telephone"></i>
        <h3>Téléphone </h3>
        <p>+213 .....</p>
      </div>
    </div>

    <div class="col-lg-3 col-md-6">
      <div class="info-item d-flex flex-column justify-content-center align-items-center" data-aos="fade-up" data-aos-delay="400">
        <i class="bi bi-envelope"></i>
        <h3>Email</h3>
        <p>sostonobile@gmail.com</p>
      </div>
    </div>

  </div>

  <div class="row gy-4 mt-1">
    <div class="col-lg-12" data-aos="fade-up" data-aos-delay="300">
    
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3212.296884185666!2d3.874171974737666!3d36.37780639139782!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x128c2f88be202109%3A0xce78f9b0040abea8!2sPOLE%20UNIVERSITAIRE!5e0!3m2!1sfr!2sdz!4v1716589519334!5m2!1sfr!2sdz" frameborder="0" style={{ border: '0', width: '100%', height: '400px' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>

  </div>

</div>

</section>


<footer>



<div class="container copyright text-center mt-4">
<p>© <span>Copyright</span> <strong class="px-1 sitename">Sos Tonobile 2024</strong><span>All Rights Reserved</span></p>
</div>

</footer>

<a href="#" id="scroll-top" class="scroll-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>

    </div>
  );
}

export default Home;
