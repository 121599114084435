import React, { useState,useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Sidebar  from '../Sidebar';
import $ from 'jquery';
import 'datatables.net';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';


import { BASE_URL,getToken,getUser,pource } from '../../config';


const AllRequestClient = () => {
        

    const navigate = useNavigate();

   let [data,setData]=useState(null);
   let [more,setMore]=useState(null);
   const [isWaiting, setIsWaiting] = useState(true);

   const [isDataTableInitialized, setDataTableInitialized] = useState(false);
   const [firstLoad, setFirstLoad] = useState(true);


   const token = getToken();
   const user_id=getUser();
 

  const fetchDepanneur = async () => {
    try {
      const response = await axios.get(`${BASE_URL}depanneur/all_client_wait/${user_id}`, {
          headers: {
            "Content-type" : "application/json",
            "Authorization" : `Bearer ${token}`,
          },
        });
        setIsWaiting(false);
         setData(response.data);
         console.log(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des informations:', error);
    }
  }

  useEffect(()=>{
    fetchDepanneur();
  },[]);
 

  useEffect(() => {
  
    if (data && data.length > 0 && !isDataTableInitialized) {
        $('#dataTable').DataTable({
            language: {
                paginate: {
                    previous: '<i class="fas fa-angle-double-left"></i>',
                    next: '<i class="fas fa-angle-double-right"></i>'
                }
            },
            // Add any other DataTable configurations here
        });
        setDataTableInitialized(true);
    } else if ((!data || data.length == 0) && isDataTableInitialized) {
        $('#dataTable').DataTable().clear().destroy(); // Clear and destroy the DataTable
        $('#dataTable').parent().html('<p class="text-center">Aucune information disponible</p>'); // Display message
        setDataTableInitialized(false);
    }
  }, [data, isDataTableInitialized]);

return (
    <div>
      <Sidebar>
        <h1 className="h3 mb-0 text-gray-800 text-start">Listes générale des demandes</h1>
        <hr className="bg-dark" />
        {isWaiting ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="card-body">
            <div className="table-responsive">
            {data && data.length > 0 ? (
                                <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                        <th className="no-sort">ID</th>
                                            <th>Nom et Prénom</th>
                                            <th>Téléphone</th>
                                            <th>Voiture</th>
                                            <th>Prix</th>
                                            <th>Date</th>
                                            <th></th>
                                            <th></th>
                                            
                                         
                                        </tr>
                                    </thead>

                                    <tbody>
                                    {data && data.map((post) => (
                                            <tr key={post.id}>
                                            <td>{post.id}</td>
                                            <td>{post.user_dep.name} {post.user_dep.last_name}</td>
                                            <td><a className='text-success' href={"tel:+"+post.user_dep.phone}>{post.user_dep.phone}</a></td>
                                           <td>{post.user_dep.depanneur.vehicule}</td>
                                           <td>{post.prix_totale} DZD</td>
                                           <td>{post.created_at}</td>
                                            <td>
                                            {post.reponse == 0 && <span className='badge bg-primary ms-3'>En Attente</span>}
                    {post.reponse == 1 && <span className='badge bg-success ms-3'>Accepté</span>}
                    {post.reponse == -1 && <span className='badge bg-warning ms-3'>annuler par client</span>}
                    {post.reponse == -2 && <span className='badge bg-warning ms-3'>annuler par dépanneur</span>}
                    {post.reponse == -3 && <span className='badge bg-danger ms-3'>Refusé</span>}
                                            </td>
                                        
                                            <td>
                                            <Link className='btn btn-info' target='_blank' to={`/helps/depannage/chemin/${post.lat_arr}/${post.long_arr}/${post.lat_depart}/${post.long_depart}`}>Click ici</Link>
                                            </td>

                                          
                                        </tr>
                                       ))}
                                        </tbody>
                            </table>
                             ) : (
                              <p className="text-center">Aucune information disponible</p>
                            )}

            </div>
          </div>
        )}
      </Sidebar>
    </div>
  );
}

  export default AllRequestClient;
