import React, { useState } from 'react';
import { GoogleMap, LoadScript, MarkerF, InfoWindowF } from '@react-google-maps/api';

import redMarker from '../../marker.png';

const PositionProfessionnel = ({ defaultCenter }) => {
    const mapStyles = {
        height: "400px",
        width: "100%"
    };

    // Icône personnalisée pour le marqueur
    const icon = {
        url: redMarker,
        scaledSize: { width: 50, height: 50 },
    };

    // État pour stocker les coordonnées de l'emplacement sélectionné et s'il est ouvert ou fermé
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [placeName, setPlaceName] = useState("");

    // Gestionnaire d'événements pour le clic sur un marqueur
    const handleMarkerClick = (location) => {
        setSelectedLocation(selectedLocation === location ? null : location);

        // Récupérer le nom de l'endroit à partir des coordonnées
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.lat},${location.lng}&key=AIzaSyAOoPr9ZlqUdNHWfYpzSbioRNJqLq39dZs`)
            .then(response => response.json())
            .then(data => {
                if (data.results && data.results.length > 0) {
                    setPlaceName(data.results[0].formatted_address);
                }
            })
            .catch(error => {
                console.error('Error fetching place name:', error);
                setPlaceName("");
            });
    };

    // Fonction pour ouvrir Google Maps avec l'itinéraire vers la position sélectionnée
    const openDirections = (location) => {
        const { lat, lng } = location;
        const url = `https://www.google.com/maps/dir/?api=1&destination=${defaultCenter.lat},${defaultCenter.lng}`;
        window.open(url, '_blank');
    };

    return (
        <LoadScript googleMapsApiKey="AIzaSyAOoPr9ZlqUdNHWfYpzSbioRNJqLq39dZs">
            <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={10}
                center={defaultCenter}
            >
                <MarkerF
                    position={defaultCenter}
                    icon={icon}
                    onClick={() => handleMarkerClick(defaultCenter)}
                />
                {selectedLocation && (
                    <InfoWindowF
                        position={{ lat: selectedLocation.lat + 0.01, lng: selectedLocation.lng }}
                        onCloseClick={() => {
                            setSelectedLocation(null);
                            setPlaceName("");
                        }}
                        options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
                    >
                        <div>
                            
                            {placeName && <div className='fw-bold'>Adresse: {placeName}</div>}
                            <button className='btn btn-primary mt-3' onClick={() => openDirections(selectedLocation)}>Démarrer l'itinéraire</button>
                        </div>
                    </InfoWindowF>
                )}
            </GoogleMap>
        </LoadScript>
    );
};

export default PositionProfessionnel;
