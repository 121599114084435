import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript ,Marker } from '@react-google-maps/api';
import Sidebar from '../Sidebar';
import { useParams } from 'react-router-dom';

import { BASE_URL,getToken,getUser } from '../../config';
import PositionProfessionnel from '../maps/PositionProfessionnel';
import axios from 'axios';



const CarteInfo = () => {

  let user_id=getUser();
  let token=getToken();
   
    const {id} = useParams();  

    const [data,setData]=useState(null)

    let [defaultCenter,setDefaultCenter]=useState(null);
 
    const [isWaiting, setIsWaiting] = useState(true);

    const [mapKey, setMapKey] = useState(0);



    const fetchAccount = async () => {
      try {
        const response = await axios.get(`${BASE_URL}position_exact/${id}`, {
            headers: {
              "Content-type" : "application/json",
              "Authorization" : `Bearer ${token}`,
            },
          });
          setIsWaiting(false);
           setData(response.data);

           setDefaultCenter({
            lat: parseFloat(response.data.lat_client),
            lng: parseFloat(response.data.long_client),
          });

          console.log(response.data);
        

      } catch (error) {
        console.error('Erreur lors de la récupération des informations:', error);
      }
    }
  
    useEffect(()=>{
      fetchAccount();
    },[]);

  
 
    return (
      <div>
        <Sidebar>
          <h1 className="h3 mb-0 text-gray-800 text-start">Position Exact : {data && data.user_client.name} {data && data.user_client.last_name} | <a href={data && data.user_client.phone}>{data && data.user_client.phone}</a>
        </h1>
          <hr className="bg-dark" />
          {isWaiting ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <>
             {data && Object.keys(data).length > 0 ? (
  <PositionProfessionnel  defaultCenter={defaultCenter} />
) : (
  <p className='text-center'>Aucune information disponible</p>
)}

            </>
          )}
        </Sidebar>
      </div>
    );    

};

export default CarteInfo;
