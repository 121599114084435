import React, { useState,useEffect } from 'react';
import { Link ,useNavigate} from 'react-router-dom';
import axios from 'axios';
import Sidebar  from './Sidebar';
import { BASE_URL,getToken,getRole,getUser } from '../config';


const Dashboard = () => {
    
  const [userinfo, setUserInfo] = useState([])
  const token=getToken();
  const role=getRole();
  const user_id=getUser();

  const navigate=useNavigate();

  const [data,setData]=useState('');

  const statistiques = async () => {
    try {

      const response = await axios.get(`${BASE_URL}statistique`, {
          headers: {
            "Content-type" : "application/json",
            "Authorization" : `Bearer ${token}`,
          },
        });
        setData(response.data);
        console.log(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des informations utilisateur :', error);
    }
  }

  useEffect(() => {
    console.log(role);
    if(role == 4){
    statistiques();
    }else if(role == 2){
        navigate('/annonces/steps');
    }else if(role == 3){
        navigate('/helps/depanneur/wait');
    }else{
        navigate('/helps/depanneur/demande');
    }
  }, [])

  return (
    <div>
    <Sidebar>
    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                        <h1 className="h3 mb-0 text-gray-800">Tableau de board</h1>
                    
                      
                    </div>

                    <div className="row">

                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-primary shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                Comptes</div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">{data.comptes}</div>
                                        </div>
                                        <div className="col-auto">
                                            <i class="fas fa-users fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-success shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                                Services</div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">{data.service}</div>
                                        </div>
                                        <div className="col-auto">
                                            <i class="fas fa-business-time fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-success shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                                Demande Dépanneur réussi</div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">{data.dep}</div>
                                        </div>
                                        <div className="col-auto">
                                            <i class="fas fa-car fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-warning shadow h-100 py-2">
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                                Demande Professionnel réussi</div>
                                            <div className="h5 mb-0 font-weight-bold text-gray-800">{data.pro}</div>
                                        </div>
                                        <div className="col-auto">
                                            <i class="fas fa-laptop-house fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                               
    </Sidebar>
    
   </div>
   
  );
};

export default Dashboard;
