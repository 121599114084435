import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import redMarker from '../../marker.png'; // Chemin vers votre icône de marqueur

const OpenStreet = () => {
  const mapRef = useRef(null);
  const center = { lat: 36.14791, lng: 3.68956 }; // Coordonnées de latitude et de longitude

  useEffect(() => {
    if (!mapRef.current) return;

    const map = L.map(mapRef.current).setView([center.lat, center.lng], 18);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    // Créer une icône personnalisée pour le marqueur
    const customIcon = L.icon({
      iconUrl: redMarker,
      iconSize: [50, 56], // Taille de l'icône en pixels
      iconAnchor: [12, 41], // Point d'ancrage de l'icône, correspond au centre de la partie inférieure de l'icône
      popupAnchor: [1, -34] // Point de l'icône qui doit correspondre à l'emplacement du popup
    });

    // Ajouter un marqueur à la position spécifiée avec l'icône personnalisée
    const marker = L.marker([center.lat, center.lng], { icon: customIcon }).addTo(map);

    return () => {
      map.remove();
    };
  }, []);

  return <div ref={mapRef} style={{ height: '400px', width: '100%' }}></div>;
};

export default OpenStreet;
