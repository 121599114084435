import React, { useState, useEffect ,useRef } from 'react';
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../Sidebar';
import { BASE_URL, getToken, Lien, getUser , getRole } from '../../config';
import PositionProfessionnel from '../maps/PositionProfessionnel';
import PositionMe from '../maps/PositionMe';

import { toast } from 'react-toastify';


const ProfessionnelSingle = () => {

  let user_id=getUser();
  let token=getToken();
  let role=getRole();

  const { id } = useParams();
  const [data, setData] = useState(null);
  const [isWaiting, setIsWaiting] = useState(true);
  const [defaultCenter, setDefaultCenter] = useState(null);
  const [now, setNow] = useState(null);
  const [mapKey, setMapKey] = useState(0);

  const modal=useRef(null);

  let [reponse, setReponse] = useState("");
  let [maps, setMaps] = useState(null);

  let [eror, setEror] = useState(false);
  let [eror2, setEror2] = useState(false);

  const [watingServer, setWatingServer] = useState(false);
  const [showModal,setShowModal]=useState(false);

  const fetchAnnonces = async () => {
    try {
      const response = await axios.get(`${BASE_URL}announces/single_annonce/${id}`, {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
      });
      setIsWaiting(false);
      setData(response.data);
      setDefaultCenter({
        lat: parseFloat(response.data.user.professionnel.lat),
        lng: parseFloat(response.data.user.professionnel.long),
      });

    } catch (error) {
      console.error('Erreur lors de la récupération des informations:', error);
      setIsWaiting(false);
    }
  };

  const sendService = async (reponse) => {
  

    const permission = window.confirm("Autoriser l'accès à votre position ?");

    if(reponse == '1'){
      
      if (permission) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // Traitement lorsque l'utilisateur autorise la géolocalisation
            console.log("Latitude :", position.coords.latitude);
            console.log("Longitude :", position.coords.longitude);

            setNow({
              lat: position.coords.latitude,
              lng: position.coords.longitude
            });
          },
          (error) => {
            console.error("Erreur lors de la récupération de la position :", error.message);
          }
        );
      } else {
        console.log("L'utilisateur a refusé l'accès à sa position.");
      }
  }
}
  
  function extractLatLongFromMapsLink(url) {
    let latitude=0;
    let longitude=0;
    var regex = new RegExp('@(.*),(.*),');
    var latLongMatch = url.match(regex);
    if (latLongMatch) {
         latitude = latLongMatch[1];
         longitude = latLongMatch[2];
    }
    return {latitude , longitude}
}

  const add=async(e)=>{
    e.preventDefault();

    if(reponse == 0){
      setEror(true);
    }
    else if(reponse == 2 && maps == null){
      setEror2(true);
      setEror(false);
    }
    else{
      setEror(false);
      setEror2(false);
      let lati=0;
      let longt=0;

      if(reponse == 1){
        lati=now.lat;
        longt=now.lng;
      }else if(reponse == 2){
        const { latitude, longitude } = extractLatLongFromMapsLink(maps);
        lati=latitude;
        longt=longitude;
      }
      const info={service_id:data.service.id,user_client:user_id,user_pro:data.user.id,lat_client:lati,long_client:longt};

      const headers = {
        "Content-type" : "application/json",
        'Authorization': `Bearer ${token}`
      };
      
      await axios.post(`${BASE_URL}services/demande_pro`, info ,{headers}).then(({data})=>{
    
      if(data.etat == true){
        setWatingServer(false);
        toast.success(data.message);
        //modal.current.hide();
        console.log(data);
        setReponse("0");
        setMaps(null);
        console.log("YESS");
      
      }else{
        setWatingServer(false);
        console.log(data);
        setShowModal(false);
        toast.error(data.message);
        console.log("Nooo");

      }

    }).catch(({response})=>{
      if(response.status == 422){
        console.log("Erros");
      }
    });


    }

  }

  useEffect(() => {
    fetchAnnonces();

    if (reponse == '1') {
      sendService(reponse);
    }
  }, [reponse]);

  useEffect(() => {
    if (now) {
      setMapKey(prevKey => prevKey + 1);
    }
  }, [now]);

  return (
    <div>
      <Sidebar>
        {isWaiting &&
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        }
        {!isWaiting && data && (
          <>
            <h1 className="h3 mb-0 text-gray-800 text-start">Entreprise : {data.user.professionnel.nom_entreprise} | {data.user.phone} | {data.user.email}</h1>
            <hr className="bg-dark" />
            <header className="bg-dark py-5 mb-5">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-4">
                    <div className="text-center text-lg-start">
                      <img src={`${Lien}${data.image}`} className="img-fluid" width="500" alt="Image" />
                    </div>
                    <hr />
                  </div>

                  <div className="col-lg-2">
                    <div className="text-center text-lg-start">
                      <p className="mt-3 badge bg-info d-block"><Link to={"/professionnel/profile/" + data.user_id} className='text-light'>{data.user.professionnel.nom_entreprise}</Link></p>
                      <p className="mt-3 badge bg-primary d-block"><Link to="" className='text-light'>{data.service.name_service}</Link></p>
                      <p className="mt-3 badge bg-danger d-block"><Link to="" className='text-light'>{data.user.wilaya.nom_wilaya}</Link></p>
                      <p className="mt-3 badge bg-danger d-block"><Link to="" className='text-light'>{data.user.adresse}</Link></p>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="text-center text-lg-start">
                      <h1 className="display-4 fw-bolder text-white">{data.title}</h1>
                      <p className="lead fw-normal text-white-50 mb-0">{data.description}</p>
                      {role == 1 &&
                      <>
                      <button className="btn btn-success mt-3" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal"><i className="fas fa-bell"></i> Demander ce service</button>
                   
                      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h1 class="modal-title fs-5" id="exampleModalLabel">Demande de service : {data.service.name_service}</h1>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                              <form className='text-start' onSubmit={add}>
                                <div class="mb-3">
                                  <label for="exampleInputEmail1" class="form-label">Choisir votre position svp</label>
                                  <select className='form-select' value={reponse} id='reponse' onChange={e => { setReponse(e.target.value) }}>
                                    <option value="0">Chosir votre position svp .....</option>
                                    <option value="1">Ma Position actuelle</option>
                                    <option value="2">Ajouter une position via maps ....</option>
                                  </select>
                                  {eror && <div id="reponse" class="form-text text-danger">Chosir une position svp ...</div>}
                                </div>

                      
                                {reponse == 2 &&
                                  <div class="mb-3">
                                    <label for="exampleInputPassword1" class="form-label">Lien maps de votre position</label>
                                    <input type="url" class="form-control" value={maps} onChange={e=>setMaps(e.target.value)} id="exampleInputPassword1" />
                                    {eror2 && <div id="reponse" class="form-text text-danger">Ajouter votre lien maps svp .....</div>}

                                  </div>
                                }
                               
  {!watingServer && <button type="submit" class="btn btn-primary mb-3"><i class="fas fa-bell"></i> Envoyer</button>}
  {watingServer && <button type="submit" class="btn btn-success mb-3" disabled><i class="fas fa-spinner"></i> Patientez s'il vous plait ...</button>}

                                {reponse == 1 &&
                                  <PositionMe key={mapKey} center={now} />
                                }
                              </form>
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-dark" data-bs-dismiss="modal">Annuler</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      </>
                }
                      
                    </div>
                  </div>
                </div>
              </div>
            </header>
            {defaultCenter && <PositionProfessionnel  defaultCenter={defaultCenter} />}
          </>
        )}
        {!isWaiting && !data && <p className="text-center">Aucune information disponible</p>}
      </Sidebar>
    </div>
  );
};

export default ProfessionnelSingle;
