import { useState ,useEffect,useRef } from "react";
import { Link , useNavigate} from "react-router-dom";
import Cookies from 'js-cookie';
import axios from 'axios';
import logo from '../assets_src/img/logo.png';
import { toast } from 'react-toastify';

import { BASE_URL,getUser } from '../config';


const Register=()=>{

  const navigate = useNavigate();

const [isProfessionnel, setIsProfessionnel] = useState(false);
const [isDepannage, setIsDepannage] = useState(false);
const [Iscommun , setIsCommun]= useState(false);

let [watingServer,setWatingServer] = useState(false);

const [custom , setCustom]= useState("");

let [maps , setMaps]= useState("");

  let [fullname,setFullName]=useState("");
  let [lastname,setLastName]=useState("");
  let [email,setEmail] = useState("");
  let [password,setPassword] = useState("");
  let [wilaya,setWilaya] = useState("");
  let [phone,setPhone] = useState("");
  let [nin,setNin] = useState("");
  let [registre,setRegistre] = useState("");
  let [vehicule,setVehicule] = useState("");
  let [job,setJob] = useState("");
  let [resume,setResume] = useState("");
  let [entreprise,setEntreprise] = useState("");
  let [siege,setSiege] = useState("");
  let [selectedWilaya, setSelectedWilaya] = useState("");
  let [adresse,setAdresse] = useState("");
  let [compte,setCompte] = useState("");
  let [selectcompte, setSelectCompte] = useState("");
 
  const [showPassword, setShowPassword] = useState(false);


  let [alert,setAlert] = useState(null);
  let [message,setMessage] = useState(null);
  let [errors,setErrors]=useState("");

  const name = useRef(null);
  const last=useRef(null);
  const mail=useRef(null);
  const iphone=useRef(null);
  const psw=useRef(null);
  const city=useRef(null);
  const region=useRef(null);
  const types=useRef(null);
  const voiture=useRef(null);
  const jobs=useRef(null);
  const resumer=useRef(null);
  const buisness=useRef(null);
  const location=useRef(null);
  const links=useRef(null);
  const nins=useRef(null);
  const registres=useRef(null);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const loadData = async () => {
    try {

      const response = await axios.get(`${BASE_URL}wilayas`, {
          headers: {
            "Content-type" : "application/json",
          },
        });
         setWilaya(response.data);

         const response2 = await axios.get(`${BASE_URL}type_comptes`, {
          headers: {
            "Content-type" : "application/json",
          },
        });
        setCompte(response2.data);

    } catch (error) {
      console.error('Erreur lors de la récupération des informations:', error);
    }
  }

  useEffect(()=>{

    const token = Cookies.get('token');
    if (token && getUser()) {
      navigate('/dashboard');
    }
    loadData();
  },[]);

  
  const handleBlur = (ref,x,style) => {
    let condition=null;
    //input
    if(style == 1){
      condition=ref.current.value.length < x;
    }else{
      condition=ref.current.value == x;
    }
    //select
    if(condition){
      ref.current.classList.add("is-invalid");
      ref.current.classList.remove("is-valid");
    }else{
      ref.current.classList.remove("is-invalid");
      ref.current.classList.add("is-valid");
    }
  };

  function extractLatLongFromMapsLink(url) {
    let latitude=0;
    let longitude=0;
    var regex = new RegExp('@(.*),(.*),');
    var latLongMatch = url.match(regex);
    if (latLongMatch) {
         latitude = latLongMatch[1];
         longitude = latLongMatch[2];
    }
    return {latitude , longitude}
}

    const create = async(e)=>{
      e.preventDefault();

      
      setWatingServer(true);

      const { latitude, longitude } = extractLatLongFromMapsLink(maps);
     
      console.log(latitude);
      console.log(longitude);
    
  
    const info= {name:fullname,last_name:lastname,email,phone,password,adresse,wilaya:selectedWilaya,account_type:selectcompte,nin,registre,vehicule,start_job:job,resume,nom_entreprise:entreprise,siege_entreprise:siege,lat:latitude,long:longitude};
    await axios.post(`${BASE_URL}register`, info).then(({data})=>{
      if(data.etat == true){
       if(data.account_type == 1){
        Cookies.set('user_id', data.jwt, { path: '/', secure: true, sameSite: 'strict' });
        localStorage.setItem('user_id',data.jwt);
        navigate('/confirm_account');
       }else{
        if(data.tst == 1){
        toast.success("Demande envoyé avec success");
        navigate('/login');
        }else{
          toast.error("vérifier votre émail s'ils vous plait , ressayer l inscription");
        }
       }
      console.log("Succefully");
      console.log(data);
      
      }else{
        console.log("Erors");
        console.log(data);

        if(data.fail == false && data.error == 0){
          setAlert('danger');
          setMessage(data.message);
        }else{
          setErrors(data.error);
          setAlert(null);
          setMessage(null);
        }
      }
      setWatingServer(false);

    }).catch(({response})=>{
      if(response.status == 422){
        console.log(response.data.errors)
          }else{
        console.log("Success");
      }
    });
}
    return (
      <div className="container-fluid">
  <section className="bg-light py-3 py-md-5">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-10 col-12">
          <div className="card border border-light-subtle rounded-3 shadow-sm">
            <div className="card-body p-3 p-md-4 p-xl-5">
              <div className="text-center mb-3">
                <Link to="/">
                  <img src={logo} alt="BootstrapBrain Logo" width="200" height="120" />
                </Link>
              </div>
              <h2 className="fs-6 fw-normal text-center text-secondary mb-4">Inscriptions</h2>

              {alert &&<div class={`alert alert-${alert}`}>
   {message}
    </div>}

    {errors && (
            <div className="alert alert-danger" role="alert">
                <ul>
                    {Object.keys(errors).map((key, index) => (
                        <li key={index}>{errors[key][0]}</li>
                    ))}
                </ul>
            </div>
        )}
              <form method="POST" onSubmit={create}>
                <div className="row gy-2 overflow-hidden">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="form-floating mb-3">
                      <input type="text" onBlur={() => handleBlur(name,3,1)} className="form-control" ref={name} name="name" id="firstName" value={fullname} onChange={e=>setFullName(e.target.value)} placeholder="First Name" required />
                      <label for="firstName" className="form-label">Nom <span className="text-danger">*</span></label>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="form-floating mb-3">
                      <input type="text"className="form-control" ref={last} onBlur={() => handleBlur(last,3,1)} name="last_name" id="last" value={lastname} onChange={e=>setLastName(e.target.value)} placeholder="Last Name" required />
                      <label for="last" className="form-label">Prénom <span className="text-danger">*</span></label>
                    </div>
                  </div>
               
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="form-floating mb-3">
                      <input type="email" className="form-control" name="email" ref={mail} onBlur={() => handleBlur(mail,6,1)} id="email" value={email} onChange={e=>setEmail(e.target.value)} placeholder="name@example.com" required />
                      <label for="email" className="form-label">Email <span className="text-danger">*</span></label>
                    </div>
                  </div>
                 
                  <div className="col-sm-12 col-md-6 col-lg-6">
      <div className="form-floating mb-3">
        <input
          type={showPassword ? 'text' : 'password'}
          className="form-control"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          ref={psw} onBlur={() => handleBlur(psw,8,1)} name="password" id="password"
          placeholder="Password"
          required
        />
        <label htmlFor="password" className="form-label">Mot de passe <span className="text-danger">*</span></label>
        <button
          className="btn"
          type="button"
          onClick={togglePasswordVisibility}
          style={{ position: 'absolute', top: '50%', right: '20px', transform: 'translateY(-50%)' }}
        >
          <i className={`fas fa-eye${showPassword ? '-slash' : ''}`}></i>
        </button>
      </div>
    </div>

                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="form-floating mb-3">
                    <select class="form-select" ref={region} onBlur={() => handleBlur(region,0,2)} onChange={e=>setSelectedWilaya(e.target.value)} name="wilaya">
                      <option value="0">Choisir une wilaya svp .......</option>
                      {wilaya && wilaya.map((post) => (
                        <option value={post.id}>{post.nom_wilaya}</option>
                        ))}
                    </select>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="form-floating mb-3">
                      <input type="text" className="form-control" ref={city} onBlur={() => handleBlur(city,3,1)} name="adresse" id="adresse" value={adresse} onChange={e=>setAdresse(e.target.value)} placeholder="Adresse" required />
                      <label for="adresse" className="form-label">Adresse <span className="text-danger">*</span></label>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="form-floating mb-3">
                      <input type="text" className="form-control" name="phone" ref={iphone} onBlur={() => handleBlur(iphone,10,1)} id="phone" value={phone} onChange={e=>setPhone(e.target.value)} placeholder="XXXXXXXXXX" required />
                      <label for="phone" className="form-label">Numéro de téléphone <span className="text-danger">*</span></label>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="form-floating mb-3">
                    <select ref={types} class="form-select" onBlur={() => handleBlur(types,0,2)} onChange={e=> {setSelectCompte(e.target.value);
                      setIsProfessionnel(e.target.value == 2);
                      setIsDepannage(e.target.value == 3);
                      setIsCommun(e.target.value == 3 || e.target.value == 2);
                    }}>
                      <option value="0">Choisir votre type de compte .......</option>
                      {compte && compte.map((post) => (
                        <option value={post.id}>{post.name_type}</option>
                        ))}
                    </select>
                    </div>
                  </div>
                  {isProfessionnel && (
                  <>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="form-floating mb-3">
                      <input type="text" className="form-control" ref={buisness} onBlur={() => handleBlur(buisness,3,1)} name="entreprise" id="ent" value={entreprise} onChange={e=>setEntreprise(e.target.value)} placeholder="" required />
                      <label for="ent" className="form-label">Nom Entreprise <span className="text-danger">*</span></label>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="form-floating mb-3">
                      <input type="text" className="form-control" ref={location} onBlur={() => handleBlur(location,3,1)} name="siege" id="siege" value={siege} onChange={e=>setSiege(e.target.value)} placeholder="" required />
                      <label for="siege" className="form-label">Siege Entreprise <span className="text-danger">*</span></label>
                    </div>
                  </div>
                  </>
                  )}
                  {Iscommun && (
                    <>
                  <div className="col-12">
                    <div className="form-floating mb-3">
                      <input type="text" className="form-control" ref={nins} onBlur={() => handleBlur(nins,22,1)} name="nin" id="nin" value={nin} onChange={e=>setNin(e.target.value)} placeholder="" required />
                      <label for="nin" className="form-label">Numéro identification nationale <span className="text-danger">*</span></label>
                    </div>
                  </div>
                  
                  
                  <div className="col-12">
                    <div className="form-floating mb-3">
                      <input type="text" className="form-control" ref={registres} onBlur={() => handleBlur(registres,10,1)} name="registre" id="registre" value={registre} onChange={e=>setRegistre(e.target.value)} placeholder="" required />
                      <label for="registre" className="form-label">Numéro de registre de commerce <span className="text-danger">*</span></label>
                    </div>
                  </div>
                  </>
                      )}

                  {isProfessionnel && 
                
                  <div className="col-12">
                    <div className="form-floating mb-3">
                      <input type="text" className="form-control" ref={links} onBlur={() => handleBlur(links,6,1)} name="maps" id="maps" value={maps} onChange={e=>setMaps(e.target.value)} placeholder="" required />
                      <label for="maps" className="form-label">Lien on maps <span className="text-danger">*</span></label>
                    </div>
                  </div>
                  }

                  {isDepannage && (
                    <>
                  <div className="col-12">
                    <div className="form-floating mb-3">
                      <input type="text" className="form-control" ref={voiture} onBlur={() => handleBlur(voiture,3,1)} name="vehicule" id="vehicule" value={vehicule} onChange={e=>setVehicule(e.target.value)} placeholder="" required />
                      <label for="vehicule" className="form-label">Nom de Véhicule <span className="text-danger">*</span></label>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-floating mb-3">
                      <input type="date" className="form-control" ref={jobs} name="job" id="job" value={job} onBlur={() => handleBlur(jobs,3,1)} onChange={e=>setJob(e.target.value)} placeholder="" required />
                      <label for="job" className="form-label">Début de travaille <span className="text-danger">*</span></label>
                    </div>
                  </div>
                  </>
                  )}

                    {Iscommun &&
                  <div className="col-12">
                    <div className="form-floating mb-3">
                      <textarea class="form-control" placeholder="" ref={resumer} onBlur={() => handleBlur(resumer,3,1)} name="resume" onChange={e=>setResume(e.target.value)}></textarea>
                      <label for="resume" className="form-label">Présenter vous <span className="text-danger">*</span></label>
                    </div>
                  </div>
                    }
                  <div className="col-12">
                    <div className="d-grid my-3">
                    {!watingServer &&<button className="btn bg-black btn-lg text-white" type="submit">Envoyer</button>}
                      {watingServer && <button type="submit" class="btn btn-primary btn-lg" disabled><i class="fas fa-spinner"></i> Patientez s'il vous plait ...</button>}

                    </div>
                  </div>
                  <div className="col-12">
                    <p className="m-0 text-secondary text-center">Vous avez déjà un compte ? <Link to="/login">Log In</Link></p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
      </div>
    );
   
}
export default Register;