import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Sidebar from '../Sidebar';
import $ from 'jquery';
import 'datatables.net';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import buisness from '../../assets_src/img/logo.png';
import { BASE_URL, getToken, Lien ,getUser,getRole } from '../../config';
import PositionProfessionnel from '../maps/PositionProfessionnel';
import { formatDistanceToNow } from 'date-fns';
import logo from '../../assets_src/img/logo.jpg';



const ProfessionnelProfile = () => {

    let token=getToken();
    let user_id=getUser();
    let role=getRole();

    const { id } = useParams();

    let [data, setData] = useState(null);
    let [annonce, setAnnonce] = useState(null);

    const [isWaiting, setIsWaiting] = useState(true);
    let [comment, setComment] = useState(null);
    let [review, setReview] = useState("");
    const [error, setError] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);

    const [searchTerm, setSearchTerm] = useState('');

    const [wilaya, setWilaya] = useState(null);
    const [service, setService] = useState(null);

    const [wilaya1, setWilaya1] = useState('');
    const [service1, setService1] = useState('');

    const [defaultCenter,setDefaultCenter] = useState(null);


    const loadData = async () => {
        try {

            const response = await axios.get(`${BASE_URL}wilayas`, {
                headers: {
                    "Content-type": "application/json",
                },
            });
            setWilaya(response.data);

            const response2 = await axios.get(`${BASE_URL}services_active`, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${token}`,

                },
            });
            setService(response2.data);

        } catch (error) {
            console.error('Erreur lors de la récupération des informations:', error);
        }
    }


    const fetchData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}announces/profile_annonce/${id}`, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });
            setData(response.data);
            console.log(response.data);

            setDefaultCenter({
                lat: parseFloat(response.data.professionnel.lat),
                lng: parseFloat(response.data.professionnel.long)
              });

        } catch (error) {
            console.error('Erreur lors de la récupération des informations:', error);
        }
    }

    const fetchAnnonces = async (page = 1, size = 5) => {
        try {
            const response = await axios.get(`${BASE_URL}announces/annouces_by_pro/${id}?page=${page}&pageSize=${size}&title=${searchTerm}&service=${service1}&wilaya=${wilaya1}`, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });
            setIsWaiting(false);
            setAnnonce(response.data.data);
            console.log(response.data.data);
            setTotalPages(response.data.last_page);
        } catch (error) {
            console.error('Erreur lors de la récupération des informations:', error);
        }
    }


    const addReview = async (e) => {
        e.preventDefault();
        if (review.length < 3) {
            setError(true);
        } else {
            setError(false);
            const services = 2;
            const info = { comment: review, user_comment: user_id, user_profile: id, services: services };
            const headers = {
                "Content-type": "application/json",
                'Authorization': `Bearer ${token}`
            };
            await axios.post(`${BASE_URL}profile/review/${id}/${services}`, info, { headers }).then(({ data }) => {
                if (data.etat == true) {
                    setReview("");
                    toast.success(data.message);
                    fetchComment();
                }else{
                    toast.error(data.message);
                }
            }).catch(({ response }) => {
                if (response.status == 422) {
                    console.log("Erros");
                }
            });
        }
    }

    const fetchComment = async () => {
        try {
            const response = await axios.get(`${BASE_URL}profile/reviews/${id}`, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });
            setComment(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des informations:', error);
        }
    }


    useEffect(() => {
        fetchData();
        fetchComment();
        loadData();
        fetchAnnonces(currentPage, pageSize);
        
       
    }, [currentPage, pageSize, searchTerm, wilaya1, service1]);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    }

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    return (
        <div>
            <Sidebar>
            <h1 className="h3 mb-0 text-gray-800 text-start">Compte proféssionnel</h1>
                <hr className="bg-dark" />
                {data && (

                    
                    <section style={{ backgroundColor: '#eee' }}>
                        <div className="container py-5">
                            <div className="row">
                                <div className="col">

                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="card mb-4">
                                        <div className="card-body text-center">
                                            <img src={buisness} alt="avatar"
                                                className="rounded-circle img-fluid" style={{ width: '150px' }} />
                                            <h5 className="my-3">{data.professionnel && data.professionnel.nom_entreprise}</h5>
                                            <p className="text-muted mb-1">{data.professionnel && data.professionnel.siege_entreprise}</p>
                                            <p className="text-muted mb-4">{data.adresse}</p>
                                            <div className="d-flex justify-content-center mb-2">
                                                <a type="button" className="btn btn-outline-primary ms-1">Offres</a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-8">
                                    <div className="card mb-4">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <p className="mb-0">Gérant</p>
                                                </div>
                                                <div className="col-sm-9">
                                                    <p className="text-muted mb-0">{data.name} {data.last_name}</p>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <p className="mb-0">Email</p>
                                                </div>
                                                <div className="col-sm-9">
                                                    <p className="text-muted mb-0">{data.email}</p>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <p className="mb-0">Téléphone</p>
                                                </div>
                                                <div className="col-sm-9">
                                                    <p className="text-muted mb-0">{data.phone}</p>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <p className="mb-0">Description</p>
                                                </div>
                                                <div className="col-sm-9">
                                                    <p className="text-muted mb-0">{data.professionnel && data.professionnel.resume}</p>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <p className="mb-0">Address</p>
                                                </div>
                                                <div className="col-sm-9">
                                                    <p className="text-muted mb-0">{data.adresse} - {data.wilaya && data.wilaya.nom_wilaya}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                   
                                        <div className="col-md-12">
                                       {data && defaultCenter && <PositionProfessionnel defaultCenter={defaultCenter} />}
                                        </div>
                                        
                                        <div className="col-md-6">
                                            {/* Project Status card 2 */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}

                <h1 className="h3 mb-0 text-gray-800 text-start mt-4">Listes des annonces</h1>
                <hr className="bg-dark" />

                {isWaiting ? (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (

                    <section className="py-2">

                        <div className='container px-4 px-lg-5'>
                            <form className="row mb-3">
                                <div className="col-md-4 mt-2">
                                    <input type="text" className="form-control" placeholder="Rechercher par titre..." value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
                                </div>
                                <div className="col-md-4 mt-2">
                                    <select className="form-select" onChange={e => setService1(e.target.value)} name="wilaya">
                                        <option value="0">Choisir un service svp .......</option>
                                        {service && service.map((post) => (
                                            <option value={post.id}>{post.name_service}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-4 mt-2">
                                    <select className="form-select" onChange={e => setWilaya1(e.target.value)} name="wilaya">
                                        <option value="0">Choisir une wilaya svp .......</option>
                                        {wilaya && wilaya.map((post) => (
                                            <option value={post.id}>{post.nom_wilaya}</option>
                                        ))}
                                    </select>
                                </div>

                            </form>

                            <hr />

                        </div>

                        {annonce && annonce.length > 0 ? (

                            <div className="container px-4 px-lg-5">


                                <div className="row gx-4 justify-content-center">

                                    {annonce && annonce.map((post) => (
                                        <div className="col-sm-12 col-md-4 col-lg-4 mb-5">
                                            <div className="card h-100">
                                                <img className="card-img-top" width={450} height={300} src={`${Lien}${post.image}`} onError={(e) => {

                                                    e.target.onerror = null;
                                                    e.target.src = 'https://dummyimage.com/450x300/dee2e6/6c757d.jpg';
                                                }} />
                                                <div className="card-body p-3">
                                                    <div className="text-center">
                                                        <h5 className="fw-bolder">{post.title}</h5>
                                                        <Link className='badge bg-primary' to="">
                                                            {post.service.name_service}
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="card-footer p-4 pt-0 border-top-0 bg-transparent">
                                                    <div className="text-center"><Link className="btn btn-outline-dark mt-auto" to={"/professionnel/single/" + post.id}>Plus de details</Link></div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>


                                <p className='d-block'>Page {currentPage} sur {totalPages}</p>
                                <nav aria-label="Page navigation example" className="d-flex justify-content-center">
                                    <ul className="pagination">

                                        <li className="page-item"><button className='page-link' onClick={prevPage} disabled={currentPage == 1}>Prévious</button></li>
                                        {Array.from({ length: totalPages }, (_, index) => (
                                            <li className={`page-item ${currentPage == index + 1 ? 'active' : ''}`} key={index}>
                                                <button className='page-link' onClick={() => setCurrentPage(index + 1)}>{index + 1}</button>
                                            </li>
                                        ))}
                                        <li className="page-item"><button className='page-link' onClick={nextPage} disabled={currentPage == totalPages}>Next</button></li>
                                    </ul>
                                </nav>



                            </div>

                                            


                        ) : (
                            <p className="text-center">Aucune information disponible</p>
                        )}

                                  
<div className="row d-flex justify-content-center reponsive">
                                <div className="col-md-12 col-lg-12 col-sm-12">
                                    <div className="card shadow-0 border" style={{ backgroundColor: '#f0f2f5' }}>
                                        <div className="card-body">
                                            {role == 1 && (
                                                <div data-mdb-input-init className="form-outline mb-4">
                                                    <form onSubmit={addReview}>
                                                        <input type="text" id="addANote" value={review} onChange={e => setReview(e.target.value)} className="form-control" placeholder="Ajouter un commentaire..." />
                                                        {error && <p className="text-danger">Le commentaire doit contenir au moins 3 caractères</p>}
                                                        <button className='btn btn-primary mt-3 mb-3'>Ajouter</button>
                                                    </form>
                                                </div>
                                            )}

                                            {comment && comment.map((post) => (
                                                <div className="card mb-4" key={post.id}>
                                                    <div className="card-body">
                                                        <p>{post.comment}</p>
                                                        <div className="d-flex justify-content-between">
                                                            <div className="d-flex flex-row align-items-center">
                                                                <img src={logo} alt="avatar" width="25" height="25" />
                                                                <p className="small mb-0">{post.user_comment.email}</p>
                                                            </div>
                                                            <div className="d-flex flex-row align-items-center">
                                                                <p className="small text-muted mb-0">il y a {formatDistanceToNow(new Date(post.created_at))}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>

                    </section>

                )}

      
            </Sidebar>

        </div>
    );
};

export default ProfessionnelProfile;
