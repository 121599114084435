import React, { useEffect, useState } from 'react';
import { Link , useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';

import logo from '../assets_src/img/logo.png';

import { BASE_URL,getUser } from '../config';


const Login = () => {
  
  const navigate = useNavigate();

useEffect(()=>{
  const token = Cookies.get('token');
  if (token && getUser()) {
    navigate('/dashboard');
  }
},[]);

  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");

  const [watingServer, setWatingServer] = useState(false);


  let [alert,setAlert] = useState(null);
  let [message,setMessage] = useState(null);

  const auth = async(e)=>{
    e.preventDefault();

    setWatingServer(true);

  const info= {email , password};
  await axios.post(`${BASE_URL}login`, info).then(({data})=>{
  
    
    console.log(data);
    if(data.etat == true){
      //the best example
  //    Cookies.set('user_id', data.user_id, { path: '/', secure: true, sameSite: 'strict' });

        //save Tokens 
    Cookies.set('token', data.token);
    Cookies.set('is_connect',true);
    Cookies.set('user_id', data.user_id);
    Cookies.set('role', data.account);

    toast.success("Connexion avec success");

    navigate('/dashboard');
    }else{
      setAlert("danger");
      setMessage(data.message);
      toast.error(data.message);
      
    }
    setWatingServer(false);

  }).catch(({response})=>{
    if(response.status == 422){
      console.log(response.data.errors);

        }
      setWatingServer(false);
  });
}

  return (
    <div className="container-fluid">
<section className="bg-light py-3 py-md-5">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-sm-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
        <div className="card border border-light-subtle rounded-3 shadow-sm">
          <div className="card-body p-3 p-md-4 p-xl-5">
            <div className="text-center mb-3">
              <Link to="/">
                <img src={logo} alt="BootstrapBrain Logo" width="200" height="130" />
              </Link>
            </div>
            <h2 className="fs-6 fw-normal text-center text-secondary mb-4">Login</h2>
            {alert &&<div className={`alert alert-${alert}`}>
            {message}
            </div>}
            <form method="POST" onSubmit={auth}>
              <div className="row gy-2 overflow-hidden">
               
                <div className="col-12">
                  <div className="form-floating mb-3">
                    <input type="email" className="form-control" name="email" id="email" value={email} onChange={e=>setEmail(e.target.value)} placeholder="name@example.com" required />
                    <label for="email" className="form-label">Email</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating mb-3">
                    <input type="password" className="form-control" name="password" id="password" value={password} onChange={e=>setPassword(e.target.value)} placeholder="Password" required />
                    <label for="password" className="form-label">Password</label>
                  </div>
                </div>
             
                <div className="col-12">
                  <div className="d-grid my-3">
                   {!watingServer && <button className="btn bg-black border-none text-white" type="submit">Connecté</button> }

                    {watingServer && <button type="submit" class="btn btn-info" disabled><i class="fas fa-spinner"></i> Patientez s'il vous plait ...</button>}


                  </div>
                </div>
                <div className="col-12">
                <p className="m-0 text-secondary text-center">Vous n'avez pas un compte ? <Link to="/register">Creer un compte</Link></p>
                </div>

                <div className="col-12">
                <p className="m-0 text-secondary text-center"> <Link to="/forgot_password">Mot de passe oublié ? </Link></p>
                </div>

              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    </div>
  );
};

export default Login;
