import React, { useState,useEffect,useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Sidebar  from '../Sidebar';
import { BASE_URL,getUser ,getToken} from '../../config';
import Swal from 'sweetalert2';


const AddAnnonce = () => {
  

  let user_id=getUser();
  let token=getToken();

  let [title,setTitle] = useState("");
  const [image, setImage] = useState('') ;
  let [description,setDescription] = useState("");
  let [service,setService] = useState("");
  let [selectedService,setSelectedService]=useState("");

  let verifyTitle = false;
  let verifyDescription = false;
  let verifyImage = false;
  let verifyservice = false;


  const fileInputRef = useRef(null);


  let [erorTitle,setErorTitle] = useState(false);
  let [erorImage,setErorImage] = useState(false);
  let [erorDesc,setErorDesc] = useState(false);
  let [erorService,setErorService] = useState(false);

  let [alert,setAlert] = useState(null);
  let [message,setMessage] = useState(null);
  let [errors,setErrors]=useState(false);
  const [watingServer, setWatingServer] = useState(false)

  
  const loadService = async () => {
    try {

      const response = await axios.get(`${BASE_URL}services_active`, {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-type" : "application/json",
          },
        });
         setService(response.data);
         console.log(response.data);

    } catch (error) {
      console.error('Erreur lors de la récupération des informations:', error);
    }
  }

  const validation=()=>{
  
  
    if(title.length < 3){
        setErorTitle(true);
        verifyTitle=false;
      }else{
          setErorTitle(false);
          verifyTitle=true;
      }
      if(selectedService == 0){
          setErorService(true);
          verifyservice=false;
        }else{
            setErorService(false);
            verifyservice=true;
        }
      if(description.length < 3){
          setErorDesc(true);
          verifyDescription=false;
        }else{
            setErorDesc(false);
            verifyDescription=true;
        }
      
        if(verifyTitle == false || verifyDescription == false || verifyservice == false){
            console.log("title is :"+verifyTitle);
            console.log("service is :"+verifyservice);
            console.log("Description is :"+verifyDescription);
          return false;
        }
      
      else{
      return true;
  
  }
}

  const changeHandler = (e)=>{
    const file=e.target.files[0];
    
    if(file == null){
        setErorImage(true);
        setImage(null);
    }else{
        setErorImage(false);
        setImage(file);
    }
}

  const add= async(e)=>{
    e.preventDefault();
    
    const test=validation();

    if(test == false){
        console.log("Eror");
        Swal.fire({
            icon: 'error',
            title: 'Erreur',
            text: 'Veuillez remplir correctement les champs.'
          });
    }

    else{
      
    setErorTitle(false);
    setErorDesc(false);
    setErorService(false);
    setWatingServer(true);

      setWatingServer(true);
      
      const formData = new FormData();
      formData.append('title', title);
      formData.append('image', image);
      formData.append('description', description);
      formData.append('user_id', user_id);
      formData.append('service_id', selectedService);
     
      const headers = {
        'Authorization': `Bearer ${token}`
      };
      
      console.log(formData);

      await axios.post(`${BASE_URL}announces/store`, formData ,{headers}).then(({data})=>{
    
        if(data.etat == true){
          setWatingServer(false);
          setAlert("success");
          setErrors(false);

          setTitle("");
          setDescription("");
          setImage("");
          setService(0);
          fileInputRef.current.value = null;
          
        }else{
          setWatingServer(false);
          setErrors(data.error);
        }
        setMessage(data.message);
        console.log(data);
      }).catch(({response})=>{
        if(response.status == 422){
          console.log("Erros");
        }
      });

    }
  }

  useEffect(()=>{
    console.log('user:'+user_id);
    loadService();
},[]);

  return (
    <div>
    <Sidebar>
    <h1 className="h3 mb-0 text-gray-800 text-start">Ajouter un nouveau offre</h1>
     <hr className="bg-dark" />
    <div className="row mt-3">
    

    {alert &&<div class={`alert alert-${alert}`}>
   {message}
    </div>}

    {errors && (
            <div className="alert alert-danger" role="alert">
                <ul>
                    {Object.keys(errors).map((key, index) => (
                        <li key={index}>{errors[key][0]}</li>
                    ))}
                </ul>
            </div>
        )}

    <form onSubmit={add} method="POST" enctype="multipart/form-data">
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Titre</label>
    <input type="text" className="form-control" id="title" value={title} onChange={e=>setTitle(e.target.value)} onBlur={validation} />
    {erorTitle && <div id="title" className="form-text text-danger">inseré un titre valide s il vous plait .</div>}
  </div>

  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Image</label>
    <input type="file" className="form-control" onChange={changeHandler} ref={fileInputRef} />
    {erorImage && <div id="image" className="form-text text-danger">inseré une image s il vous plait .</div>}
  </div>

  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Service</label>
    <select className='form-select' onChange={e=> {setSelectedService(e.target.value)}} onBlur={validation}>
    <option value='0'>Choisir un service s'ils vous plait .....</option>
    {service && service.map((item)=>(
        <option value={item.id}>{item.name_service}</option>
    ))}
    </select>
    {erorService && <div id="image" className="form-text text-danger">Choisir un service s'ils vous plait .</div>}
  </div>

  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Description</label>
    <textarea className="form-control" id="description" rows="4" value={description} onChange={e=>setDescription(e.target.value)} onBlur={validation}></textarea>
    {erorDesc && <div id="description" className="form-text text-danger">inseré une description valide s il vous plait .</div>}
  </div>

  {!watingServer && <button type="submit" class="btn btn-primary"><i class="fas fa-sd-card"></i> Envoyer</button>}
  {watingServer && <button type="submit" class="btn btn-success" disabled><i class="fas fa-spinner"></i> Patientez s'il vous plait ...</button>}
</form>
    </div>

    </Sidebar>
    </div>
  );
};

export default AddAnnonce;
