import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { BASE_URL, getToken, getUser, getRole } from '../../config';
import Sidebar from '../Sidebar';
import logo from '../../assets_src/img/logo.jpg';
import { toast } from 'react-toastify';
import { formatDistanceToNow } from 'date-fns';


const ProfileDepanneur = () => {
    let token = getToken();
    let user_id = getUser();
    let role = getRole();
    const { id } = useParams();

    let [data, setData] = useState(null);
    let [comment, setComment] = useState(null);
    let [review, setReview] = useState("");
    const [error, setError] = useState(null);
    const [isWaiting, setIsWaiting] = useState(true);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}depanneur/profile/${id}`, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });
            setData(response.data);
            setIsWaiting(false);
        } catch (error) {
            console.error('Erreur lors de la récupération des informations:', error);
        }
    }

    const addReview = async (e) => {
        e.preventDefault();
        if (review.length < 3) {
            setError(true);
        } else {
            setError(false);
            const services = 3;
            const info = { comment: review, user_comment: user_id, user_profile: id, services: services };
            const headers = {
                "Content-type": "application/json",
                'Authorization': `Bearer ${token}`
            };
            await axios.post(`${BASE_URL}profile/review/${id}/${services}`, info, { headers }).then(({ data }) => {
                if (data.etat == true) {
                    setReview("");
                    toast.success(data.message);
                    fetchComment();
                }else{
                    toast.error(data.message);
                }
            }).catch(({ response }) => {
                if (response.status == 422) {
                    console.log("Erros");
                }
            });
        }
    }

    const fetchComment = async () => {
        try {
            const response = await axios.get(`${BASE_URL}profile/reviews/${id}`, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });
            setComment(response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des informations:', error);
        }
    }

    useEffect(() => {
        fetchData();
        fetchComment();
    }, []);

    return (
        <div>
            <Sidebar>
                <h1 className="h3 mb-0 text-gray-800 text-start">Compte de dépanneur</h1>
                <hr className="bg-dark" />
                {isWaiting ? (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <section style={{ backgroundColor: '#eee' }}>
                        <div className="container py-5">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="card mb-4">
                                        <div className="card-body text-center">
                                            <img src={logo} alt="avatar" className="rounded-circle img-fluid" style={{ width: '150px' }} />
                                            <h5 className="my-3">{data.name} {data.last_name}</h5>
                                            <p className="text-muted mb-1">{data.depanneur.vehicule}</p>
                                            <p className="text-muted mb-4">{data.adresse}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="card mb-4">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <p className="mb-0">Dépannage</p>
                                                </div>
                                                <div className="col-sm-9">
                                                    <p className="text-muted mb-0">{data.name} {data.last_name}</p>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <p className="mb-0">Email</p>
                                                </div>
                                                <div className="col-sm-9">
                                                    <p className="text-muted mb-0">{data.email}</p>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <p className="mb-0">Téléphone</p>
                                                </div>
                                                <div className="col-sm-9">
                                                    <p className="text-muted mb-0">{data.phone}</p>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <p className="mb-0">Description</p>
                                                </div>
                                                <div className="col-sm-9">
                                                    <p className="text-muted mb-0">{data.depanneur.resume}</p>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <p className="mb-0">Adresse</p>
                                                </div>
                                                <div className="col-sm-9">
                                                    <p className="text-muted mb-0">{data.adresse} - {data.wilaya.nom_wilaya}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row d-flex justify-content-center reponsive">
                                <div className="col-md-12 col-lg-12 col-sm-12">
                                    <div className="card shadow-0 border" style={{ backgroundColor: '#f0f2f5' }}>
                                        <div className="card-body">
                                            {role == 1 && (
                                                <div data-mdb-input-init className="form-outline mb-4">
                                                    <form onSubmit={addReview}>
                                                        <input type="text" id="addANote" value={review} onChange={e => setReview(e.target.value)} className="form-control" placeholder="Ajouter un commentaire..." />
                                                        {error && <p className="text-danger">Le commentaire doit contenir au moins 3 caractères</p>}
                                                        <button className='btn btn-primary mt-3 mb-3'>Ajouter</button>
                                                    </form>
                                                </div>
                                            )}

                                            {comment && comment.map((post) => (
                                                <div className="card mb-4" key={post.id}>
                                                    <div className="card-body">
                                                        <p>{post.comment}</p>
                                                        <div className="d-flex justify-content-between">
                                                            <div className="d-flex flex-row align-items-center">
                                                                <img src={logo} alt="avatar" width="25" height="25" />
                                                                <p className="small mb-0">{post.user_comment.email}</p>
                                                            </div>
                                                            <div className="d-flex flex-row align-items-center">
                                                                <p className="small text-muted mb-0">il y a {formatDistanceToNow(new Date(post.created_at))}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            </Sidebar>
        </div>
    );
};

export default ProfileDepanneur;
