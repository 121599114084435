import React, { useState,useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Sidebar  from '../Sidebar';
import $ from 'jquery';
import 'datatables.net';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';


import { BASE_URL,getToken,getUser,pource } from '../../config';


const WaitClientDepanneur = () => {
        

    const navigate = useNavigate();

   let [data,setData]=useState(null);
   let [more,setMore]=useState(null);
   const [isWaiting, setIsWaiting] = useState(true);

   const [isDataTableInitialized, setDataTableInitialized] = useState(false);
   const [firstLoad, setFirstLoad] = useState(true);


   const token = getToken();
   const user_id=getUser();
 

  const fetchDepanneur = async () => {
    try {
      const response = await axios.get(`${BASE_URL}depanneur/all_client_wait/${user_id}`, {
          headers: {
            "Content-type" : "application/json",
            "Authorization" : `Bearer ${token}`,
          },
        });
        setIsWaiting(false);
         setData(response.data);
         console.log(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des informations:', error);
    }
  }

  useEffect(()=>{
    
    const fetchData = async () => {
        await fetchDepanneur();
        setIsWaiting(false);
    };

    // Appel initial pour obtenir les données
    fetchData();

    // Rafraîchir les données toutes les 30 secondes
    const interval = setInterval(fetchData, 20000);

    // Arrêter l'intervalle lors du démontage du composant
    return () => clearInterval(interval);

  },[]);


 
  const decision_depanneur = async (id, decision) => {
    let rep="";
    let etat="";
    if(decision == 1){
      rep="Êtes-vous sûr de vouloir accepté cette course";
      etat="question";
    }else{
      rep="Êtes-vous sûr de vouloir annuler cette course";
      etat="warning";
    }
    Swal.fire({
      title: rep,
      text: "Cette action est irréversible!",
      icon: etat,
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler'
  }).then(async (result) => {
      if (result.isConfirmed) {
    try {
        const response = await axios.post(`${BASE_URL}depanneur/reponse/${id}/${decision}`, null, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`,
            }
        });
        
        if (response.data.etat == true) {
            console.log("Success");
            toast.success(response.data.message);
        } else {
            console.log('error');
            toast.error(response.data.message);
        }
            fetchDepanneur();
    } catch (error) {
        if (error.response && error.response.status == 422) {
            console.log(error.response.data.errors);
        } else {
            console.log(error);
        }
    }
  }
})
}

return (
    <div>
      <Sidebar>
        <h1 className="h3 mb-0 text-gray-800 text-start">Listes des demandes en attentes</h1>
        <hr className="bg-dark" />
        {isWaiting ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="card-body">
            <div className="table-responsive">
              {data && data.length > 0 ? (
                data.map((post) => (
                  <div class="card mt-3" key={post.id}>
                    <div class="card-header">Dépannage 
                    {post.reponse == 0 && <span className='badge bg-primary ms-3'>En Attente</span>}
                    {post.reponse == 1 && <span className='badge bg-success ms-3'>Accepté</span>}
                    {post.reponse == -1 && <span className='badge bg-warning ms-3'>annuler par client</span>}
                    {post.reponse == -2 && <span className='badge bg-warning ms-3'>annuler par dépanneur</span>}
                    {post.reponse == -3 && <span className='badge bg-danger ms-3'>Refusé</span>}
                    <span className='ms-3 badge bg-primary'>{post.created_at}</span>
                    </div>
                    <div class="card-body">
                      <h5 class="card-title">Nom : <span className='text-primary'>{post.user_dep.name} {post.user_dep.last_name}</span></h5>
                      <h5 class="card-title">Telephone : <a className='text-success' href={"tel:+"+post.user_dep.phone}>{post.user_dep.phone}</a></h5>
                      <h5 class="card-title">Voiture : {post.user_dep.depanneur.vehicule}</h5>

                      <h5 class="card-title">Prix : <span className='text-primary'>{post.prix_totale} DZD</span></h5>
                      <h5 class="card-title">Details : <Link className='text-info' target='_blank' to={`/helps/depannage/chemin/${post.lat_arr}/${post.long_arr}/${post.lat_depart}/${post.long_depart}`}>Click ici</Link></h5>
                   {(post.reponse == 0 || post.reponse == 1) && 
                      <button class="btn btn-danger m-2" onClick={()=>decision_depanneur(post.id,-1)}>annuler la demande</button>
                   }
                      <Link target='_blank' to={"/helps/depanneur/profile/"+post.user_dep.id} className='btn btn-success m-2'>Voir le profile</Link>
                    </div>
                  </div>  
                ))
              ) : (
                <p className="text-center">Aucune information disponible</p>
              )}
            </div>
          </div>
        )}
      </Sidebar>
    </div>
  );
}

  export default WaitClientDepanneur;
