import React, { useEffect, useState } from 'react';
import { Link , useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';

import logo from '../assets_src/img/logo.png';

import { BASE_URL } from '../config';
import {jwtDecode} from "jwt-decode";



const RessetPassword = () => {
  
  const navigate = useNavigate();

  let [current,setCurrent] = useState("");
  let [news,setNews] = useState("");
  
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };

useEffect(()=>{
  const token = Cookies.get('token');
  if (token) {
    navigate('/dashboard');
  }
},[]);

const [watingServer, setWatingServer] = useState(false);


  let [alert,setAlert] = useState(null);
  let [message,setMessage] = useState(null);


  const add = async(e)=>{
    e.preventDefault();

    setWatingServer(true);
    
    const jwt = Cookies.get('email_reset');
    const decoded = jwtDecode(jwt);
    const email_reset=decoded.email;
    

    const info={current,news};
    await axios.post(`${BASE_URL}auth/forgot_password/${email_reset}`,info).then(({data})=>{
    
        if(data.etat == true){
          setWatingServer(false);
          toast.success(data.message);
          navigate("/login");
          console.log(data);
         //Cookies.remove('email_reset');
          
        }else{
          setWatingServer(false);
          console.log(data);
           toast.error(data.message);
        }
        setWatingServer(false);

      }).catch(({response})=>{
        if(response.status == 422){
          console.log("Erros");
          setWatingServer(false);
        }
      });
 
}

  return (
    <div className="container-fluid">
<section className="bg-light py-3 py-md-5">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-sm-12 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
        <div className="card border border-light-subtle rounded-3 shadow-sm">
          <div className="card-body p-3 p-md-4 p-xl-5">
            <div className="text-center mb-3">
            <Link to="/">
                <img src={logo} alt="BootstrapBrain Logo" width="200" height="130" />
              </Link>
            </div>
            <h2 className="fs-6 fw-normal text-center text-secondary mb-4">Nouveau mot de passe</h2>
            <hr/>
            {alert &&<div className={`alert alert-${alert}`}>
            {message}
            </div>}
              
    <form onSubmit={add}>
  

    <div className="mb-3">
        <label htmlFor="newPassword1" className="form-label">Nouveau mot de passe</label>
        <div className="input-group">
          <input type={showPassword1 ? 'text' : 'password'} className="form-control" id="newPassword1" required minLength="8" value={current} onChange={e => setCurrent(e.target.value)} />
          <button type="button" className="btn btn-outline-secondary" onClick={togglePasswordVisibility1}>
            <i className={`fas fa-eye${showPassword1 ? '-slash' : ''}`}></i>
          </button>
        </div>
      </div>


  <div className="mb-3">
        <label htmlFor="newPassword" className="form-label">Confirmer nouveau mot de passe</label>
        <div className="input-group">
          <input type={showPassword ? 'text' : 'password'} className="form-control" id="newPassword" required minLength="8" value={news} onChange={e => setNews(e.target.value)} />
          <button type="button" className="btn btn-outline-secondary" onClick={togglePasswordVisibility}>
            <i className={`fas fa-eye${showPassword ? '-slash' : ''}`}></i>
          </button>
        </div>
      </div>

  {!watingServer && <button type="submit" class="btn bg-black text-white"><i class="fas fa-user-edit"></i> Confirmer</button>}
  {watingServer && <button type="submit" class="btn btn-info" disabled><i class="fas fa-spinner"></i> Patientez s'il vous plait ...</button>}
</form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    </div>
  );
};

export default RessetPassword;
