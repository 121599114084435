import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Sidebar  from '../Sidebar';
import $ from 'jquery';
import 'datatables.net';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';


import { BASE_URL , Lien ,getUser , getToken } from '../../config';


const AnnonceBy = () => {

  let user_id=getUser();
  let token=getToken();
        
   let [data,setData]=useState(null);
   let [more,setMore]=useState(null);
   const [isWaiting, setIsWaiting] = useState(true);

   const [isDataTableInitialized, setDataTableInitialized] = useState(false);
 

  const fetchAnnouces = async () => {
    try {
      const response = await axios.get(`${BASE_URL}announces/by/${user_id}`, {
          headers: {
            "Content-type" : "application/json",
            "Authorization" : `Bearer ${token}`,
          },
        });
        setIsWaiting(false);
         setData(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des informations:', error);
    }
  }

  useEffect(()=>{
   
    fetchAnnouces();
  },[]);


useEffect(() => {
  
  if (data && data.length > 0 && !isDataTableInitialized) {
      $('#dataTable').DataTable({
          language: {
              paginate: {
                  previous: '<i class="fas fa-angle-double-left"></i>',
                  next: '<i class="fas fa-angle-double-right"></i>'
              }
          },
          // Add any other DataTable configurations here
      });
      setDataTableInitialized(true);
  } else if ((!data || data.length == 0) && isDataTableInitialized) {
      $('#dataTable').DataTable().clear().destroy(); // Clear and destroy the DataTable
      $('#dataTable').parent().html('<p class="text-center">Aucune information disponible</p>'); // Display message
      setDataTableInitialized(false);
  }
}, [data, isDataTableInitialized]);


  return (
    <div>
    <Sidebar>
    <h1 className="h3 mb-0 text-gray-800 text-start">Mes Annonces</h1>
     <hr className="bg-dark" />
     {isWaiting ? (
       <div className="d-flex justify-content-center">
       <div className="spinner-border" role="status">
           <span className="visually-hidden">Loading...</span>
       </div>
   </div>
    ) : (
     <div class="card-body">
                            <div class="table-responsive">
                            {data && data.length > 0 ? (
                                <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                        <th>Image</th>
                                        <th className="no-sort">ID</th>
                                            <th>Titre</th>
                                            <th>Description</th>
                                            <th>Service</th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                    {data && data.map((post) => (
                                            <tr key={post.id}>
                                              <td>
                                                <a href={`${Lien}${post.image }`} target='_blank'>
                                              <img width="80px" src={`${Lien}${post.image }`} /> 
                                              </a>
                                              </td>
                                              <td>{post.id}</td>
                                            <td>{post.title}</td>
                                            <td>{post.description}</td>
                                            <td>{post.service.name_service}</td>
                                            <td>{post.created_at}</td>
                                            <td>
                                            {post.isvalide == 1 && <span className='badge bg-success'>Active</span>}
                                            {post.isvalide == 2 && <span className='badge bg-warning'>Bloqué</span>}
                                            {post.isvalide == 0 && <span className='badge bg-primary'>En attente</span>}
                                            {post.isvalide == -1 && <span className='badge bg-danger'>Refusé</span>}

                                            </td>
                                          
                                        </tr>
                                       ))}
                                        </tbody>
                            </table>
                             ) : (
                              <p className="text-center">Aucune information disponible</p>
                            )}
                            </div>
                            </div>
                            )}
    </Sidebar>
    </div>
  );
};

export default AnnonceBy;
