import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';
import { BASE_URL, getToken, getUser, getRole } from '../../config';
import Sidebar from '../Sidebar';
import logo from '../../assets_src/img/logo.jpg';
import { toast } from 'react-toastify';
import { formatDistanceToNow } from 'date-fns';


const ProfileCompte = () => {
    let token = getToken();
    let user_id = getUser();
    let role = getRole();

    let [data, setData] = useState(null);
    let [comment, setComment] = useState(null);
    let [review, setReview] = useState("");
    const [error, setError] = useState(null);
    const [isWaiting, setIsWaiting] = useState(true);

    const fetchData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}auth/profile/${user_id}`, {
                headers: {
                    "Content-type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });
            setData(response.data);
            console.log(response.data);
            setIsWaiting(false);
        } catch (error) {
            console.error('Erreur lors de la récupération des informations:', error);
        }
    }

   useEffect(()=>{
    fetchData();
   },[]);

    return (
        <div>
            <Sidebar>
                <h1 className="h3 mb-0 text-gray-800 text-start">Les informations du comptes</h1>
                <hr className="bg-dark" />
                {isWaiting ? (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <section style={{ backgroundColor: '#eee' }}>
                        <div className="container py-5">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="card mb-4">
                                        <div className="card-body text-center">
                                            <img src={logo} alt="avatar" className="rounded-circle img-fluid" style={{ width: '150px' }} />
                                            <h5 className="my-3">{data.name} {data.last_name}</h5>
                                            <p className="text-muted mb-4">{data.adresse}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="card mb-4">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <p className="mb-0">Profile</p>
                                                </div>
                                                <div className="col-sm-9">
                                                    <p className="text-muted mb-0">{data.name} {data.last_name}</p>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <p className="mb-0">Email</p>
                                                </div>
                                                <div className="col-sm-9">
                                                    <p className="text-muted mb-0">{data.email}</p>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <p className="mb-0">Téléphone</p>
                                                </div>
                                                <div className="col-sm-9">
                                                    <p className="text-muted mb-0">{data.phone}</p>
                                                </div>
                                            </div>
                                            <hr />
                                         
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <p className="mb-0">Adresse</p>
                                                </div>
                                                <div className="col-sm-9">
                                                    <p className="text-muted mb-0">{data.adresse} - {data.wilaya.nom_wilaya}</p>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <p className="mb-0">Date de création</p>
                                                </div>
                                                <div className="col-sm-9">
                                                    <p className="text-muted mb-0">{data.created_at}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                       
                        </div>
                    </section>
                )}
            </Sidebar>
        </div>
    );
};

export default ProfileCompte;
