import React, { useEffect, useState } from 'react';
import { Link , useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import {jwtDecode} from "jwt-decode";
import logo from '../assets_src/img/logo.png';

import { BASE_URL } from '../config';


const Confirm = () => {
  
  const navigate = useNavigate();
  
  const jwt = Cookies.get('user_id');

    const decoded = jwtDecode(jwt);
    const user_id=decoded.user_id;

    let [alert,setAlert] = useState(null);
    let [message,setMessage] = useState(null);

useEffect(()=>{
  const user = Cookies.get('user_id');

  if (!user) {
    navigate('/login');
  }

},[]);

  let [otp, setOtp] = useState("");

  const verify = async(e)=>{
    e.preventDefault();

  const info= {otp,user_id};

  await axios.post(`${BASE_URL}confirm`, info).then(({data})=>{
    //save Tokens 
    console.log(data);
    if(data.etat== true){
        Cookies.remove('user_id');
        navigate('/login');
    }else{
        setAlert("danger");
        setMessage(data.message);
    }
    
  }).catch(({response})=>{
    if(response.status == 422){
      console.log(response.data.errors)
        }else{
      console.log("Success");
    }
  });
}

  return (
    <div className="container-fluid">
<section className="bg-light py-3 py-md-5">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
        <div className="card border border-light-subtle rounded-3 shadow-sm">
          <div className="card-body p-3 p-md-4 p-xl-5">
            <div className="text-center mb-3">
              <a href="#!">
                <img src={logo} alt="BootstrapBrain Logo" width="200" height="120" />
              </a>
            </div>
            <h2 className="fs-6 fw-normal text-center text-secondary mb-4">Confirmation du compte</h2>
            {alert &&<div class={`alert alert-${alert}`}>
   {message}
    </div>}
            <form method="POST" onSubmit={verify}>
              <div className="row gy-2 overflow-hidden">
               
                <div className="col-12">
                  <div className="form-floating mb-3">
                    <input type="text" className="form-control" name="otp" id="otp" value={otp} onChange={e=>setOtp(e.target.value)} placeholder="Code Otp" required />
                    <label for="otp" className="form-label">Code Otp</label>
                  </div>
                </div>
           
             
                <div className="col-12">
                  <div className="d-grid my-3">
                    <button className="btn btn-primary btn-lg" type="submit">Envoyer</button>
                  </div>
                </div>
                <div className="col-12">
                <p className="m-0 text-secondary text-center">Vous avez pas un compte ? <Link to="/login">Login</Link></p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    </div>
  );
};

export default Confirm;
