import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Sidebar  from '../Sidebar';
import $ from 'jquery';
import 'datatables.net';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';


import { BASE_URL , getToken , Lien } from '../../config';


const Professionnel = () => {

  let token=getToken();
        
   let [data,setData]=useState(null);
   const [isWaiting, setIsWaiting] = useState(true); 

   const [currentPage, setCurrentPage] = useState(1);
   const [pageSize, setPageSize] = useState(3);
   const [totalPages, setTotalPages] = useState(0);

   const [searchTerm, setSearchTerm] = useState('');

   const[wilaya,setWilaya]=useState(null);
   const [service,setService]=useState(null);

   const[wilaya1,setWilaya1]=useState('');
   const [service1,setService1]=useState('');

   const loadData = async () => {
    try {

        const response = await axios.get(`${BASE_URL}wilayas`, {
            headers: {
            "Content-type" : "application/json",
          },
        });
         setWilaya(response.data);

         const response2 = await axios.get(`${BASE_URL}services_active`, {
            headers: {
            "Content-type" : "application/json",
            "Authorization" : `Bearer ${token}`,

          },
        });
        setService(response2.data);

    } catch (error) {
      console.error('Erreur lors de la récupération des informations:', error);
    }
  }


  const fetchAnnonces = async (page = 1, size = 5) => {
    try {
      const response = await axios.get(`${BASE_URL}announces_list?page=${page}&pageSize=${size}&title=${searchTerm}&service=${service1}&wilaya=${wilaya1}`, {
          headers: {
            "Content-type" : "application/json",
            "Authorization" : `Bearer ${token}`,
          },
        });
         setIsWaiting(false);
         setData(response.data.data);
         console.log(response.data.data);
         setTotalPages(response.data.last_page);
    } catch (error) {
      console.error('Erreur lors de la récupération des informations:', error);
    }
  }

  useEffect(() => {
    loadData();
    fetchAnnonces(currentPage, pageSize);
  }, [currentPage, pageSize , searchTerm ,wilaya1 , service1]);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  }
  
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  return (
    <div>
    <Sidebar>
    <h1 className="h3 mb-0 text-gray-800 text-start">Listes des annonces</h1>
     <hr className="bg-dark" />
     
     {isWaiting ? (
       <div className="d-flex justify-content-center">
       <div className="spinner-border" role="status">
           <span className="visually-hidden">Loading...</span>
       </div>
   </div>
    ) : (

     <section className="py-2">

        <div className='container px-4 px-lg-5'>
        <form className="row mb-3">
  <div className="col-md-4 mt-2">
    <input type="text" className="form-control" placeholder="Rechercher par titre..." value={searchTerm} onChange={e=>setSearchTerm(e.target.value)} />
  </div>
  <div className="col-md-4 mt-2">
  <select class="form-select" onChange={e=>setService1(e.target.value)} name="wilaya">
                      <option value="0">Choisir un service svp .......</option>
                      {service && service.map((post) => (
                        <option value={post.id}>{post.name_service}</option>
                        ))}
                    </select>
  </div>
  <div className="col-md-4 mt-2">
  <select class="form-select" onChange={e=>setWilaya1(e.target.value)} name="wilaya">
                      <option value="0">Choisir une wilaya svp .......</option>
                      {wilaya && wilaya.map((post) => (
                        <option value={post.id}>{post.nom_wilaya}</option>
                        ))}
                    </select>
  </div>

</form>

<hr/>
      
        </div>

        {data && data.length > 0 ? (

            <div className="container px-4 px-lg-5">
              
                <div className="row gx-4 justify-content-center">
                     
                {data && data.map((post) => (
                    <div className="col-sm-12 col-md-4 col-lg-4 mb-4">
                        <div className="card h-100">
                            <img className="card-img-top" width={450} height={300} src={`${Lien}${post.image}`}  onError={(e) => {

                        e.target.onerror = null; 
                        e.target.src = 'https://dummyimage.com/450x300/dee2e6/6c757d.jpg'; 
                    }} />
                            <div className="card-body p-3">
                                <div className="text-center">
                                    <h5 className="fw-bolder">{post.title}</h5>
                                    <Link className='badge bg-primary' to="">
                                       {post.service.name_service}
                                    </Link>
                                </div>
                            </div>
                            <div className="card-footer p-4 pt-0 border-top-0 bg-transparent">
                                <div className="text-center"><Link class="btn btn-outline-dark mt-auto" to={"/professionnel/single/"+post.id}>Plus de details</Link></div>
                            </div>
                        </div>
                    </div>
                ))}
                    </div>
                   
                    
      
                    <p className='d-block'>Page {currentPage} sur {totalPages}</p>
      <nav aria-label="Page navigation example" class="d-flex justify-content-center">
  <ul class="pagination">
    
    <li className="page-item"><button className='page-link' onClick={prevPage} disabled={currentPage == 1}>Prévious</button></li>
    {Array.from({ length: totalPages }, (_, index) => (
      <li className={`page-item ${currentPage == index + 1 ? 'active' : ''}`} key={index}>
        <button className='page-link' onClick={() => setCurrentPage(index + 1)}>{index + 1}</button>
      </li>
    ))}
    <li className="page-item"><button className='page-link' onClick={nextPage} disabled={currentPage == totalPages}>Next</button></li>
  </ul>
</nav>



                    </div>
                      ) : (
                        <p className="text-center">Aucune information disponible</p>
                      )}
                    </section>  
                    )}   
    </Sidebar>
    </div>
  );
};

export default Professionnel;
