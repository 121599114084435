import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Sidebar  from '../Sidebar';
import $ from 'jquery';
import 'datatables.net';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';


import { BASE_URL,getUser } from '../../config';


const ListNotification = () => {
        
  const user_id=getUser();

   let [data,setData]=useState(null);
   let [more,setMore]=useState(null);
   const [isWaiting, setIsWaiting] = useState(true);

   const [isDataTableInitialized, setDataTableInitialized] = useState(false);

   const token = Cookies.get('token');
 

   const fetchNotification = async () => {
    try {

      const response = await axios.get(`${BASE_URL}notification/all/${user_id}`, {
          headers: {
            "Content-type" : "application/json",
            "Authorization" : `Bearer ${token}`,
          },
        });
        setIsWaiting(false);
        setData(response.data);
        console.log(response.data);
        
    } catch (error) {
      console.error('Erreur lors de la récupération des informations utilisateur :', error);
    }
  }

  useEffect(()=>{
   
    fetchNotification();
  },[]);


useEffect(() => {
  
  if (data && data.length > 0 && !isDataTableInitialized) {
      $('#dataTable').DataTable({
          language: {
              paginate: {
                  previous: '<i class="fas fa-angle-double-left"></i>',
                  next: '<i class="fas fa-angle-double-right"></i>'
              }
          },
          // Add any other DataTable configurations here
      });
      setDataTableInitialized(true);
  } else if ((!data || data.length == 0) && isDataTableInitialized) {
      $('#dataTable').DataTable().clear().destroy(); // Clear and destroy the DataTable
      $('#dataTable').parent().html('<p class="text-center">Aucune information disponible</p>'); // Display message
      setDataTableInitialized(false);
  }
}, [data, isDataTableInitialized]);

  return (
    <div>
    <Sidebar>
    <h1 className="h3 mb-0 text-gray-800 text-start">Listes des notifications</h1>
     <hr className="bg-dark" />
     {isWaiting ? (
       <div className="d-flex justify-content-center">
       <div className="spinner-border" role="status">
           <span className="visually-hidden">Loading...</span>
       </div>
   </div>
    ) : (
     <div class="card-body">
                            <div class="table-responsive">
                            {data && data.length > 0 ? (
                                <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                        <th className="no-sort">ID</th>
                                            <th>Sender</th>
                                            <th>Message</th>
                                            <th>Service</th>
                                            <th></th>
                                         
                                        </tr>
                                    </thead>

                                    <tbody>
                                    {data && data.map((post) => (
                                            <tr key={post.id}>
                                              <td>{post.id}</td>
                                            <td>{post.user_send.name} {post.user_send.last_name} | {post.user_send.phone}</td>
                                            <td>{post.message}</td>
                                            <td>
                                             {post.demande == 3 && <span className='badge bg-success'>Dépannage</span>}
                                             {post.demande == 2 && <span className='badge bg-primary'>Professionnel</span>}
                                             {post.demande == 4 && <span className='badge bg-primary'>Compte</span>}

                                            </td>
                      
                                            <td>{post.created_at}</td>
                                            
                                        </tr>
                                       ))}
                                        </tbody>
                            </table>
                             ) : (
                              <p className="text-center">Aucune information disponible</p>
                            )}
                            </div>
                            </div>
                            )}
    </Sidebar>
    </div>
  );
};

export default ListNotification;
