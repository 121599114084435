import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Sidebar  from '../Sidebar';

import { BASE_URL, getToken, getUser } from '../../config';


const AddService = () => {
 
  let user_id=getUser();
  let token=getToken();

  let [service,setService] = useState("");
  let [eror,setEror] = useState(false);
  let [alert,setAlert] = useState(null);
  let [message,setMessage] = useState(null);
  const [watingServer, setWatingServer] = useState(false);


  const add= async(e)=>{
    e.preventDefault();
    if(service.length < 3){
      setEror(true);
    }else{
      setEror(false);
      setWatingServer(true);
      const info= {name_service: service,user_id:user_id};

      const headers = {
        "Content-type" : "application/json",
        'Authorization': `Bearer ${token}`
      };
      
      await axios.post(`${BASE_URL}create_service`,info ,{headers}).then(({data})=>{
    
        if(data.success == true){
          setWatingServer(false);
          setAlert("success");
          setService("");
        }else{
          setWatingServer(false);
          setAlert("danger");
        }
        setMessage(data.message);

      }).catch(({response})=>{
        if(response.status == 422){
          console.log("Erros");
        }
      });

    }
  }

  return (
    <div>
    <Sidebar>
    <h1 className="h3 mb-0 text-gray-800 text-start">Ajouter un nouveau service</h1>
     <hr className="bg-dark" />
    <div className="row mt-3">
    
{alert &&<div class={`alert alert-${alert}`}>
   {message}
    </div>}
    <form onSubmit={add}>
  <div class="mb-3">
    <label for="exampleInputEmail1" class="form-label">Nom de service</label>
    <input type="text" className="form-control" id="service" value={service} onChange={e=>setService(e.target.value)} />
    {eror && <div id="service" className="form-text text-danger">inseré un nom de service valide s il vous plait .</div>}
  </div>

  {!watingServer && <button type="submit" class="btn btn-primary"><i class="fas fa-sd-card"></i> Envoyer</button>}
  {watingServer && <button type="submit" class="btn btn-success" disabled><i class="fas fa-spinner"></i> Patientez s'il vous plait ...</button>}
</form>
    </div>

    </Sidebar>
    </div>
  );
};

export default AddService;
