import {useState,useEffect } from "react";
import $ from 'jquery';
import Cookies from 'js-cookie';
import axios from 'axios';

import '../assets_src/css/sb-admin-2.min.css';
import '../assets_src/css/style.css';

import '../assets_src/library/fontawesome-free/css/all.min.css';
import { Link,useNavigate } from "react-router-dom";
import logo from '../assets_src/img/logo.jpg';

import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import { BASE_URL,getToken,getRole,getUser } from '../config';

const Sidebar = ({children}) => {
    

    let user_id=getUser();
    let token=getToken();

    const navigate = useNavigate();
     
    let [name,setName]=useState("");
    let [msg,setMsg]=useState(0);
     
    let [notificationTake,setNotificationTake]=useState(null);
    let [notnumber,setNotNumber]=useState(null);

    let[alertmsg,setAlertMsg]=useState("");
    let[alertbtn,setAlertBtn]=useState("");
    let[message,setMessage]=useState("");
    let[st,setSt]=useState("");

    useEffect(()=>{

        if (!token || !user_id) {
            navigate('/login');
          }

        fetchUsers();
        fetchNotificationTake();

      
        
        if(getRole() == 3){
        fetchStatusDepanneur();
        }

      },[]);


      const fetchNotificationTake = async () => {
        try {
    
          const response = await axios.get(`${BASE_URL}notification/take/${user_id}`, {
              headers: {
                "Content-type" : "application/json",
                "Authorization" : `Bearer ${token}`,
              },
            });
            setNotificationTake(response.data);
            setNotNumber(response.data.length);
            
        } catch (error) {
          console.error('Erreur lors de la récupération des informations utilisateur :', error);
        }
      }

      const fetchUsers = async () => {
        try {
    
          const response = await axios.get(`${BASE_URL}userinfo`, {
              headers: {
                "Content-type" : "application/json",
                "Authorization" : `Bearer ${token}`,
              },
            });
            setName(response.data.name);
            setMsg(response.data.id+1);
            console.log(response.data);
        
        } catch (error) {
          console.error('Erreur lors de la récupération des informations utilisateur :', error);
        }
      }

      const fetchStatusDepanneur = async () => {
        try {
    
          const response = await axios.get(`${BASE_URL}depanneur_status/fetch/${user_id}`, {
              headers: {
                "Content-type" : "application/json",
                "Authorization" : `Bearer ${token}`,
              },
            });

            if(response.data.etat.connection == 1){
                setAlertMsg("success");
                setMessage("En Ligne");
                setSt("Desactivé");
                setAlertBtn("warning");

            }else{
                setAlertMsg("danger");
                setMessage("Hors Ligne");
                setSt("Activé");
                setAlertBtn("primary");
            }
          
        
        } catch (error) {
          console.error('Erreur lors de la récupération des informations utilisateur :', error);
        }
      }

      const logout = async () => {
     
        await axios.post(`${BASE_URL}logout`,null,{
            headers: {
                "Content-type" : "application/json",
                "Authorization" : `Bearer ${token}`,
            }
        }).then(({ data }) => {
            console.log(data);
            Cookies.remove('user_id');
            Cookies.remove('token');
            Cookies.remove('is_connect');
            Cookies.remove('role');

            logout_dep();

            navigate('/');
        }).catch(({ response }) => {
            if (response.status === 422) {
                console.log(response.data.errors);
            } else {
                console.log("Success");
            }
        });
    }  
    
    
    const logout_dep = async () => {
     
        await axios.post(`${BASE_URL}depanneur_logging/${user_id}`,null,{
            headers: {
                "Content-type" : "application/json",
                "Authorization" : `Bearer ${token}`,
            }
        }).then(({ data }) => {
            console.log("success");
            
        }).catch(({ response }) => {
            if (response.status === 422) {
                console.log(response.data.errors);
            } else {
                console.log("Success");
            }
        });
    }


    const status = async (decision) => {
     
        await axios.post(`${BASE_URL}depanneur_status/${user_id}`,null,{
            headers: {
                "Content-type" : "application/json",
                "Authorization" : `Bearer ${token}`,
            }
        }).then(({ data }) => {
            if(data.etat == 1){
               
                setAlertMsg("success");
                setMessage("En Ligne");

                setSt("Desactivé");
                setAlertBtn("warning");

            }else{
                setAlertMsg("danger");
                setMessage("Hors Ligne");

                setSt("Activé");
                setAlertBtn("primary");

            }
            toast.success(data.message);
        }).catch(({ response }) => {
            if (response.status === 422) {
                console.log(response.data.errors);
            } else {
                console.log("Success");
            }
        });
    }    


    const vue=async(e)=>{
        try{
        const response = await axios.post(`${BASE_URL}notification/update/${user_id}`, null, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`,
            }
        });
        if (response.data.etat === true) {
            console.log("Success");
            navigate('/auth/notification');
        } else {
            console.log('error');
        }
    } catch (error) {
        if (error.response && error.response.status === 422) {
            console.log(error.response.data.errors);
        } else {
            console.log(error);
        }
    }
    }
    

  return (
    <div>
  <div id="wrapper">
   
  <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion d-none d-md-block" id="accordionSidebar">
    <li className="nav-item">
        <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/dashboard">
            <div className="sidebar-brand-text mx-3" id="okk">
                <img src={logo} alt="Logo" width="120" height="85" />
            </div>
        </Link>
    </li>

    <hr className="sidebar-divider my-0" />
  
    { getRole() == 4 &&
    <>
    <li className="nav-item active">
        <Link className="nav-link" to="/Dashboard">
            <i className="fas fa-fw fa-tachometer-alt"></i>
            <span>Tableau de board</span>
            
        </Link>
    </li>

    <hr className="sidebar-divider" />

    <div className="sidebar-heading">
        APP
    </div>


<li className="nav-item">
    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo"
        aria-expanded="true" aria-controls="collapseTwo">
        <i className="fas fa-fw fa-cog"></i>
        <span>Gestions des services</span>
    </a>
    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
        <div className="bg-white py-2 collapse-inner rounded">
            <h6 className="collapse-header">Ajouter</h6>
            <Link className="collapse-item" to="/services/add">Ajouter</Link>
            <Link className="collapse-item" to="/services/list">Listes</Link>
        </div>
    </div>
</li>

    <li className="nav-item">
        <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities"
            aria-expanded="true" aria-controls="collapseUtilities">
            <i className="fas fa-users"></i>
            <span>Gestions des comptes</span>
        </a>
        <div id="collapseUtilities" className="collapse" aria-labelledby="headingUtilities"
            data-parent="#accordionSidebar">
            <div className="bg-white py-2 collapse-inner rounded">
                <Link className="collapse-item" to="/comptes/en_attente">En Attente</Link>
                <Link className="collapse-item" to="/comptes/professionnel">Compte Professionnel</Link>
                <Link className="collapse-item" to="/comptes/depanneur">Compte Dépanneur</Link>
                <Link className="collapse-item" to="/comptes/client">Clients</Link>
            </div>
        </div>
    </li>
    </>
}
    <hr className="sidebar-divider" />

    <div className="sidebar-heading">
        APP
    </div>

 {(getRole() == 4 || getRole() == 2) && (
    <li className="nav-item">
        <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages"
            aria-expanded="true" aria-controls="collapsePages">
            <i class="fas fa-blog"></i>
            <span>Annonces</span>
        </a>
        <div id="collapsePages" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
            <div className="bg-white py-2 collapse-inner rounded">
              {getRole() == 2 && 
              <>
                <Link className="collapse-item" to="/annonces/add">Ajouter une annonce</Link>
                <Link className="collapse-item" to="/annonces/steps">Mes Annonces</Link>
                </>
                }
                {getRole() == 4 && 
                <>
                <Link className="collapse-item" to="/annonces/attente">Annonces en attentes</Link>
                
                <Link className="collapse-item" to="/annonces/all">Annonces</Link>
                <Link className="collapse-item" to="/annonces/archive">Archives</Link>
                    </>
                    }

            </div>
        </div>
    </li>
)}

 {(getRole() == 4 || getRole() == 2 || getRole() == 1) && (
    <li className="nav-item">
        <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseHelps"
            aria-expanded="true" aria-controls="collapseHelps">
            <i class="fas fa-hand-holding-medical"></i>
            <span>Aides</span>
        </a>
        <div id="collapseHelps" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
            <div className="bg-white py-2 collapse-inner rounded">
                <Link className="collapse-item" to="/helps/professionnel">Professionnel</Link>
                {getRole() == 2 &&
                <>
                <Link className="collapse-item" to="/helps/professionnel/demande/attente">Demande en attente</Link>
                <Link className="collapse-item" to="/helps/professionnel/demande/traiter">Demande traité</Link>
                </>
                }
                {getRole() == 1 &&
                <Link className="collapse-item" to="/helps/professionnel/demande/client">Demande clients</Link>
                }
                {getRole() == 4 &&
                <Link className="collapse-item" to="/helps/professionnel/all">Tous Les Demandes</Link>
                }


            </div>
        </div>
    </li>
)}

{(getRole() == 4 || getRole() == 3 || getRole() == 1) && (
    <li className="nav-item">
        <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseDep"
            aria-expanded="true" aria-controls="collapseDep">
            <i class="fas fa-car"></i>
            <span>Dépannage</span>
        </a>
        <div id="collapseDep" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
            <div className="bg-white py-2 collapse-inner rounded">
                {getRole() == 1 &&
                <>
                <Link className="collapse-item" to="/helps/depanneur/demande">Demande dépanneur</Link>
                <Link className="collapse-item" to="/helps/depanneur/waitclient">Demandes Instantané</Link>
                <Link className="collapse-item" to="/helps/depanneur/client/allrequest">Tous les demandes</Link>
                </>
                }
                {getRole() == 3 &&
                <>               
                 <Link className="collapse-item" to="/helps/depanneur/wait">Demandes Instantané</Link>
                <Link className="collapse-item" to="/helps/depanneur/depanneur/allrequest">Tous les demandes</Link>
                </>
                }

                {getRole() == 4 && 
                <Link className="collapse-item" to="/helps/depanneur/admin/allrequest">Tous les demandes</Link>

               }
                
            </div>
        </div>
    </li>
)}

{getRole() == 4 && 
  <li class="nav-item">
  <Link class="nav-link" to="/auth/admin/reviews">
      <i class="fas fa-fw fa-table"></i>
      <span>Reviews</span></Link>
</li>
}

    <hr className="sidebar-divider d-none d-md-block" />

    <div className="text-center d-none d-md-inline">
        <button className="rounded-circle border-0" id="sidebarToggle"></button>
    </div>

 
</ul>

        <div id="content-wrapper" className="d-flex flex-column">

            <div id="content">

                <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                    <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                        <i className="fa fa-bars"></i>
                    </button>
                           {getRole() == 3 &&
                            <div className="row mt-3">
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                <button className={`btn btn-${alertbtn}`} type="button" onClick={status}>
                                    {st}
                                </button>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6">
                                <p className={`badge bg-${alertmsg}`}>{message}</p>
                                </div>
                            </div>
                            }

                    <ul className="navbar-nav ml-auto">


                        <li className="nav-item dropdown no-arrow mx-1">
                            <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fas fa-bell fa-fw"></i>
                                <span className="badge badge-danger badge-counter">{notnumber}</span>
                            </a>
                            <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                aria-labelledby="alertsDropdown">
                                <h6 className="dropdown-header text-white bg-dark">
                                    notifications
                                </h6>
                                {notificationTake && notificationTake.map((post) => (
                                <Link className="dropdown-item d-flex align-items-center" to="">
                                    <div className="mr-3">
                                        <div className="icon-circle bg-primary">
                                            <i className="fas fa-file-alt text-white"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="small text-gray-500">{post.created_at}</div>
                                        <span className="font-weight-bold">{post.message}</span>
                                    </div>
                                </Link>
                                 ))}
                                <Link className="dropdown-item text-center small text-gray-500" onClick={vue}>Tous les notification</Link>
                            </div>
                        </li>

                        
                        <div className="topbar-divider d-none d-sm-block"></div>

                        <li className="nav-item dropdown no-arrow">
                            <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span className="mr-2 d-none d-lg-inline text-gray-600 small">{name}</span>
                                
                                    <img src={logo} alt="BootstrapBrain Logo" className="img-profile rounded-circle" width="120" height="85" />
                            </a>
                            <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
                                aria-labelledby="userDropdown">
                                     <p className="dropdown-item fw-bold">
                                        {getRole() == 1 && <span>utilisateur</span>}
                                        {getRole() == 2 && <span>Professionnel</span>}
                                        {getRole() == 3 && <span>Dépanneur</span>}
                                        {getRole() == 4 && <span>Administrateur</span>}
                                </p>

                                <Link className="dropdown-item" to="/dashboard">
                                <i class="fas fa-home fa-sm fa-fw mr-2 text-gray-400"></i>
                                Accueil                                
                                
                                </Link>

                                <Link className="dropdown-item" to={
                        getRole() == 1 || getRole() == 4
                        ? "/auth/profile/"+user_id
                            : getRole() == 3
                         ? "/helps/depanneur/profile/"+user_id
                         : "/professionnel/profile/"+user_id
}>
                                    <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Profile
                                </Link>

                                <Link className="dropdown-item" to="/auth/profile/password">
                                <i class="fas fa-key fa-sm fa-fw mr-2 text-gray-400"></i>
                                Mot de passe                                
                                
                                </Link>
                               
                            
                                <div className="dropdown-divider"></div>
                                <button className="dropdown-item" onClick={logout}>
                                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                    Déconnecté
                                </button>
                            </div>
                        </li>

                    </ul>

                </nav>
               
               {/* START DASHBOARD */}

               <div className="container-fluid text-start">
                   {children}

                </div>
               {/* END DASHBOARD  */}          
            </div>

        </div>

    </div>
    <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
    </a>
    </div>

  );
}

export default Sidebar;
