import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import Sidebar  from '../Sidebar';
import $ from 'jquery';
import 'datatables.net';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';


import { BASE_URL,getToken , Lien } from '../../config';


const AnnonceAttente = () => {
        
   let [data,setData]=useState(null);
   let [more,setMore]=useState(null);
   const [isWaiting, setIsWaiting] = useState(true);

   const [isDataTableInitialized, setDataTableInitialized] = useState(false);

   const token = getToken();
 

  const fetchAccount = async () => {
    try {
      const response = await axios.get(`${BASE_URL}announces/attente`, {
          headers: {
            "Content-type" : "application/json",
            "Authorization" : `Bearer ${token}`,
          },
        });
        setIsWaiting(false);
         setData(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des informations:', error);
    }
  }

  useEffect(()=>{
   
    fetchAccount();
  },[]);



 
  const reponse_announce = async (id, decision) => {
    let rep="";
    let etat="";
    if(decision == 1){
      rep="Êtes-vous sûr de vouloir accepté cette annonce";
      etat="question";
    }else{
      rep="Êtes-vous sûr de vouloir refusé cette annonce";
      etat="warning";
    }
    Swal.fire({
      title: rep,
      text: "Cette action est irréversible!",
      icon: etat,
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler'
  }).then(async (result) => {
      if (result.isConfirmed) {
    try {
        const response = await axios.post(`${BASE_URL}reponse_announce/${id}/${decision}`, null, {
            headers: {
                "Content-type": "application/json",
                "Authorization": `Bearer ${token}`,
            }
        });
        
        if (response.data.etat == true) {
            console.log("Success");
            toast.success(response.data.message);
        } else {
            console.log('error');
            toast.error(response.data.message);
        }

        const updatedUsers = data.filter(post => post.id !== id);
        setData(updatedUsers);

          if (updatedUsers.length == 0) {
            $('#dataTable').DataTable().clear().destroy(); 
              setDataTableInitialized(false);
          }
    } catch (error) {
        if (error.response && error.response.status == 422) {
            console.log(error.response.data.errors);
        } else {
            console.log(error);
        }
    }
  }
});
}


useEffect(() => {
  
  if (data && data.length > 0 && !isDataTableInitialized) {
      $('#dataTable').DataTable({
          language: {
              paginate: {
                  previous: '<i class="fas fa-angle-double-left"></i>',
                  next: '<i class="fas fa-angle-double-right"></i>'
              }
          },
          // Add any other DataTable configurations here
      });
      setDataTableInitialized(true);
  } else if ((!data || data.length == 0) && isDataTableInitialized) {
      $('#dataTable').DataTable().clear().destroy(); // Clear and destroy the DataTable
      $('#dataTable').parent().html('<p class="text-center">Aucune information disponible</p>'); // Display message
      setDataTableInitialized(false);
  }
}, [data, isDataTableInitialized]);


  return (
    <div>
    <Sidebar>
    <h1 className="h3 mb-0 text-gray-800 text-start">Listes des Annonces En Attentes</h1>
     <hr className="bg-dark" />
     {isWaiting ? (
       <div className="d-flex justify-content-center">
       <div className="spinner-border" role="status">
           <span className="visually-hidden">Loading...</span>
       </div>
   </div>
    ) : (
     <div class="card-body">
                            <div class="table-responsive">
                            {data && data.length > 0 ? (
                                <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                        <th>Image</th>
                                        <th className="no-sort">ID</th>
                                            <th>Titre</th>
                                            <th>Description</th>
                                            <th>Service</th>
                                            <th>User</th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                         
                                        </tr>
                                    </thead>

                                    <tbody>
                                    {data && data.map((post) => (
                                            <tr key={post.id}>
                                              <td>
                                                <a href={`${Lien}${post.image }`} target='_blank'>
                                              <img width="80px" src={`${Lien}${post.image }`} /> 
                                              </a>
                                              </td>
                                              <td>{post.id}</td>
                                            <td>{post.title}</td>
                                            <td>{post.description}</td>
                                            <td>{post.service.name_service}</td>
                                            
                                            <td>{post.user.name}</td>
                                            <td>{post.created_at}</td>
                                            <td>
                                                <button className='btn btn-success' onClick={()=>reponse_announce(post.id,1)}>
                                                <i class="fas fa-check-double"></i>
                                                </button>
                                            </td>

                                            <td>
                                                <button className='btn btn-danger' onClick={()=>reponse_announce(post.id,0)}>
                                                <i class="fas fa-ban"></i> 
                                                </button>
                                            </td>

                                          
                                        </tr>
                                       ))}
                                        </tbody>
                            </table>
                             ) : (
                              <p className="text-center">Aucune information disponible</p>
                            )}
                            </div>
                            </div>
                            )}
    </Sidebar>
    </div>
  );
};

export default AnnonceAttente;
